<template>
  <VDialog
    v-model="visibility"
    max-width="700px"
    content-class="cross__dialog dialog-order"
    @click:outside="typeDialog = 0"
    :transition="$dialogTransition"
  >
    <VCard class="boardSettingDialog">
      <v-fade-transition>
        <v-progress-linear
          v-if="loading"
          absolute
          top
          indeterminate
          color="success"
        ></v-progress-linear>
      </v-fade-transition>
      <div class="cross__inner">
        <VBtn fab small elevation="0" @click="closeDialog">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </div>
      <VRow class="ma-0 align-center justify-space-between boardSettingDialog__header pt-4">
        <VCol cols="12" md="6">
          <VRow no-gutters>
            <span class="boardSettingDialog__header-title" style="padding: 8px 24px">
              {{ getDialogTitle }}
            </span>
          </VRow>
          <VRow no-gutters v-if="editBoard.uuid_user_created != getUser.uuid && boardList.length">
            <v-expansion-panels flat>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  {{ $t("crm.boardOwnerInfo") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-simple-table class="my-table supply" dense>
                    <thead>
                      <tr>
                        <td>{{ $t("table.name") }}</td>
                        <td>{{ $t("table.email") }}</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ editBoard?.owner?.name }}</td>
                        <td class=" copyTxt" @click="CopyToClipboard(editBoard?.owner?.email)">
                          {{ editBoard?.owner?.email }}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </VRow>
        </VCol>
        <VCol cols="12" md="6" class="boardSettingDialog__header-nav">
          <VBtn
            @click="changeDefault"
            v-if="editBoard.uuid_user_created == getUser.uuid && typeDialog == 0"
            icon
            large
            color="yellow darken-2"
          >
            <VIcon v-if="editBoard.is_default == '1'">mdi-star</VIcon>
            <VIcon v-if="editBoard.is_default == '0'">mdi-star-outline</VIcon>
          </VBtn>
          <VAutocomplete
            :items="boardList"
            item-text="name"
            item-value="uuid"
            :label="$t('form.choose')"
            v-model="boardItem"
            :hide-details="true"
            @change="changeBoard"
            dense
            outlined
            return-object
            :disabled="typeDialog != 0"
            :menu-props="{ offsetY: true }"
            color="success"
            item-color="success"
            @keypress="validationForTitle($event)"
            @paste="validationForTitle($event)"
          >
            <template slot="item" slot-scope="{ item }">
              {{ item.name }}
            </template>
            <template slot="selection" slot-scope="{ item }">
              {{ item.name }}
            </template>
          </VAutocomplete>
          <VBtn
            class="ml-2 btn-settingBoard text-white text-transform-none br-10"
            :class="`${typeDialog == 0 ? ' success-bg' : 'error-bg'}`"
            @click="changeTypeDialog"
            :disabled="!boardList.length || !permissions.can_edit_board"
          >
            <VIcon v-if="typeDialog">mdi-close</VIcon>
            <VIcon v-else>mdi-plus</VIcon>
          </VBtn>
        </VCol>
      </VRow>
      <VCardText>
        <VTabsItems v-model="typeDialog">
          <VTabItem>
            <VRow class="my-0 py-0">
              <VCol cols="12">
                <VTextField
                  v-model="editBoard.name"
                  outlined
                  dense
                  color="success"
                  class="br-10"
                  :label="$t('form.title')"
                  :hide-details="!nameEditErrors.length"
                  :error-messages="nameEditErrors"
                  :disabled="!permissions.can_edit_board"
                  :maxlength="30"
                  @keypress="validationForTitle($event)"
                  @paste="validationForTitle($event)"
                />
              </VCol>
              <VCol cols="12">
                <VTextarea
                  height="104"
                  no-resize
                  rows="3"
                  color="success"
                  class="br-10"
                  :placeholder="$t('form.addComment')"
                  v-model="editBoard.description"
                  outlined
                  counter
                  :maxlength="255"
                  dense
                  :disabled="!permissions.can_edit_board"
                ></VTextarea>
              </VCol>
              <VCol cols="12" class="d-flex">
                <VTextField
                  v-model="editBoard.uuid"
                  outlined
                  dense
                  color="green"
                  class="br-10"
                  label="UUID"
                  hide-details
                  disabled
                />
                <VBtn
                  class="ml-2 btn-settingBoard success-bg text-white text-transform-none br-10"
                  @click="copyToClipboard(editBoard.uuid)"
                >
                  <VIcon>mdi-content-copy</VIcon>
                </VBtn>
              </VCol>
              <boardCashiersList :boardItem="boardItem" v-if="boardItem != null" />
            </VRow>
          </VTabItem>
          <VTabItem>
            <VRow class="my-0 py-0">
              <VCol cols="12">
                <VTextField
                  v-model="newBoard.name"
                  outlined
                  dense
                  color="success"
                  class="br-10"
                  :label="$t('crm.enterBoardName')"
                  :hide-details="!nameAddErrors.length"
                  :error-messages="nameAddErrors"
                />
              </VCol>
              <VCol cols="12">
                <VTextarea
                  height="104"
                  no-resize
                  rows="3"
                  color="success"
                  class="br-10"
                  :placeholder="$t('form.addComment')"
                  v-model="newBoard.description"
                  outlined
                  dense
                  counter
                  :maxlength="255"
                ></VTextarea>
              </VCol>
            </VRow>
          </VTabItem>
        </VTabsItems>
      </VCardText>
      <VCardActions>
        <VContainer>
          <VRow class="my-0 py-0">
            <VCol cols="6" class="my-0 py-0">
              <VBtn
                v-if="typeDialog === 0"
                class="w-100 br-10 text-transform-none"
                dark
                @click="$emit('close')"
              >
                {{ $t("btn.cancel") }}
              </VBtn>
            </VCol>
            <VCol cols="6" class="my-0 py-0">
              <VBtn
                class="success-bg text-white text-transform-none br-10 w-100"
                v-if="typeDialog === 0"
                @click="updateBoardItem"
                :disabled="isChanged || !permissions.can_edit_board || loading"
              >
                {{ $t("btn.update") }}
              </VBtn>
              <VBtn
                class="success-bg text-white text-transform-none br-10 w-100"
                v-else-if="typeDialog === 1"
                @click="createNewBoard"
              >
                {{ $t("btn.create") }}
              </VBtn>
            </VCol>
          </VRow>
        </VContainer>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import * as getterTypes from "@/store/modules/crm/types/getters";
import language from "../../../mixins/language";
import user from "../../../mixins/user";
import notifications from "../../../mixins/notifications";
import crmService from "../../../services/request/crm/crmService";
import EventBus from "../../../events/EventBus";
import { mapGetters } from "vuex";
import _ from "lodash";
import { validationForTitle } from "@/mixins/helpers";
import boardAddCashierDialog from "./boardAddCashierDialog.vue";
import crmFormsService from "@/services/request/crm/crmForms";
import boardCashiersList from "./boardCashiersList.vue";

export default {
  name: "SettingBoardDialog",
  components: {
    boardAddCashierDialog,
    boardCashiersList
  },
  mixins: [validationMixin, language, notifications, user],
  data: () => ({
    loading: false,
    boardItem: null,
    typeDialog: 0,

    editBoard: {
      name: "",
      description: "",
      uuid: ""
    },
    cashiers: [],
    cashierDefault: 0,
    newBoard: {
      name: "",
      description: ""
    }
  }),
  async mounted() {
    await this.fetchData();
  },
  methods: {
    validationForTitle,
    CopyToClipboard(data) {
      navigator.clipboard.writeText(data);
      this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
    },
    async copyToClipboard(uuid) {
      try {
        await navigator.clipboard.writeText(uuid);
        this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
      } catch (e) {
        console.log(e);
      }
    },
    changeDefault() {
      if (this.editBoard.is_default == "0") {
        this.editBoard.is_default = (1 - Number(this.editBoard.is_default)).toString();
        this.updateBoardItem(false);
      }
    },
    fetchData() {
      const boardItem = this.boardList.filter(item => item.uuid === this.uuid_board);
      if (!this.boardList.length && this.permissions.can_edit_board) {
        this.typeDialog = 1;
      }
      this.boardItem = boardItem.length ? boardItem[0] : null;

      this.getDataItemBoard();
    },
    changeBoard() {
      // orderDialog('boardItem', this.boardItem);
      //
      if (this.typeDialog === 2 && this.isBoardCreator) {
        this.fetchSharedUsers();
      } else {
        this.typeDialog = 0;
      }
      this.getDataItemBoard();
    },
    changeTypeDialog() {
      this.$v.$reset();
      if (this.typeDialog === 2) {
        this.typeDialog = 0;
        return;
      }
      this.typeDialog = this.typeDialog === 1 ? 0 : 1;
    },
    async getDataItemBoard() {
      try {
        if (this.boardItem) {
          await crmService.getCrmBoardsItemInfo(this.boardItem.uuid).then(request => {
            // // orderDialog('request', request);
            this.editBoard = {
              ...this.editBoard,
              ...request
            };
          });
          EventBus.$emit("crm-cashier-added", this.boardItem);
        }
      } catch (e) {
        console.log(e);
      }
    },

    clearNewBoard() {
      this.newBoard = {
        name: "",
        description: ""
      };
    },
    async createNewBoard() {
      this.$v.newBoard.$touch();
      if (!this.$v.newBoard.$invalid) {
        try {
          const formData = new FormData();
          formData.append("name", this.newBoard.name);
          formData.append("description", this.newBoard.description);

          const request = await crmService.addCrmBoardsItem(formData);

          this.typeDialog = 0;
          this.visibility = false;
          EventBus.$emit("crm-board-items-reset", request.data.object);
          this.setSuccessNotification("Успішно створено!");
          this.clearNewBoard();
        } catch (e) {
          console.log(e);
        }
      }
    },
    async updateBoardItem(close = true) {
      this.$v.editBoard.$touch();
      if (!this.$v.editBoard.$invalid) {
        try {
          const formData = new FormData();
          formData.append("uuid", this.editBoard.uuid);
          const changedData = this.$getChangedData(this.editBoard, this.boardItem);
          changedData.forEach(data => {
            formData.append(data.field, data.value);
          });

          const res = await crmService.updateCrmBoardsItem(formData);

          if (close) {
            EventBus.$emit("crm-board-items-reset", res.data.object);
            this.setSuccessNotification("Успішно оновлено!");
            this.$emit("close");
          } else {
            this.boardItem = res.data.object;
            this.getDataItemBoard();
            EventBus.$emit("crm-board-items-reset", res.data.object);
            this.setSuccessNotification("Успішно оновлено!");
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    onClose() {
      this.$v.$reset();
      this.$emit("close");
    },
    closeDialog() {
      this.typeDialog = 0;
      this.onClose();
    }
  },
  computed: {
    ...mapGetters("crm", {
      uuid_board: getterTypes.GET_UUID_BOARD
    }),

    isChanged() {
      return _.isEqual(this.editBoard, this.boardItem);
    },

    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
        this.$v.$reset();
      }
    },
    getDialogTitle() {
      let value = "";
      switch (this.typeDialog) {
        case 0:
          value = this.$t("crm.settingtsTitle");
          break;
        case 1:
          value = this.$t("crm.creationBoardTitle");
          break;
        default:
          return;
      }
      return value;
    },
    nameAddErrors() {
      const errors = [];
      if (!this.$v.newBoard.name.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.newBoard.name.required && errors.push(this.$t("form.errors.TitleRequierd"));
      return errors;
    },

    nameEditErrors() {
      const errors = [];
      if (!this.$v.editBoard.name.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.editBoard.name.required && errors.push(this.$t("form.errors.TitleRequierd"));
      return errors;
    }
  },
  props: {
    boardList: {
      required: true
    },
    visible: {
      required: true,
      type: Boolean
    }
  },
  validations: {
    newBoard: {
      name: { required }
    },
    editBoard: {
      name: { required }
    }
  },
  watch: {
    visibility: {
      handler() {
        if (!this.boardList.length) {
          this.typeDialog = 1;
        }
      }
    },
    boardItem: {
      deep: true,
      handler() {
        console.log(this.boardItem);
      }
    }
  }
};
</script>

<style lang="scss">
.boardSettingDialog {
  &__header {
    &-title {
      padding: 32px 24px 5px 24px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
    }
    &-nav {
      display: flex;
      padding: 12px 24px;
    }
  }
}
.params-frame {
  border-radius: 10px;
  border: 1px solid #dcdcdc;
}

.icon-btn {
  pointer-events: auto;
}

#custom-disabled.v-btn--disabled {
  opacity: 0.5;
  background: #4fae3b !important;
  color: #ffff !important;
}

.txt {
  span,
  b {
    font-weight: normal;
    font-size: 16px;
    color: #676767;
  }

  b {
    font-weight: 500;
    color: #3d3d3d;
  }
}

.change-pass {
  border-radius: 10px;
  padding: 14px 56px;
  border: 1px solid #dcdcdc;
  position: relative;

  span {
    position: absolute;
    text-align: center;
    left: 0;
    width: 100%;

    pointer-events: none;
  }

  &.sm {
    padding: 14px 16px;
  }
}

.btn-addCounterparties {
  min-width: 42px !important;
  padding: 0 !important;
}
</style>
