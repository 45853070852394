<template>
  <div class="custom-colorPicker">
    <div class="custom-colorPicker__item"
         v-for="(item, index) in colors"
         :key="index"
         :style="`background-color: ${item.color};`"
         :class="{'active': item.color === value}"
         @click="setColor(item.color)"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'ColorPicker',
  data: () => ({
    colors: [
      {
        color: '#FFFFB0',
      },
      {
        color: '#FFEBA0',
      },
      {
        color: '#FFDCA0',
      },
      {
        color: '#FEE5C0',
      },
      {
        color: '#FF8160',
      },
      {
        color: '#FAFFC0',
      },
      {
        color: '#FCFFD0',
      },
      {
        color: '#FFF5E5',
      },
      {
        color: '#FFE9E5',
      },
      {
        color: '#FF89A0',
      },
      {
        color: '#EFFF9E',
      },
      {
        color: '#EEFDFF',
      },
      {
        color: '#F9F9F9',
      },
      {
        color: '#FFEFFF',
      },
      {
        color: '#FF9CE0',
      },
      {
        color: '#B3FFA0',
      },
      {
        color: '#DBFFF5',
      },
      {
        color: '#D1EEFB',
      },
      {
        color: '#D1D3FB',
      },
      {
        color: '#FFC7DF',
      },
      {
        color: '#A4FFA0',
      },
      {
        color: '#C6F7FF',
      },
      {
        color: '#8CD3FF',
      },
      {
        color: '#8C8CFF',
      },
      {
        color: '#A080FF',
      },
    ],
  }),
  props: {
    value: String,
  },
  methods: {
    setColor(val) {
      this.$emit('input', val);
    },
  },
};
</script>

<style lang="scss">
.custom-colorPicker{
  background-color: #fff;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 234px;
  &__item{
    width: 34px;
    height: 34px;
    border: 1px solid #CAC8C8;
    border-radius: 3px;
    margin: 4px;
    cursor: pointer;
    &.active{
      border: 1px solid #3D3D3D;
    }
  }
}
</style>
