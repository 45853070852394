<template>
    <div>
      <v-simple-table class="my-table supply" dense>
        <thead>
        <tr style="border-bottom:2px solid;">
          <th style="border:1px solid #D3D3D3;" class="text-left">{{$t('crm.stats_tag')}}</th>
          <th style="border:1px solid #D3D3D3;" class="text-left">{{$t('table.amount')}}</th>
        </tr>
        <tr v-if="Object.keys(tagsData).length" v-for="(value, key) in tagsData" :key="key">
          <th style="border:1px solid #D3D3D3;" class="text-right">{{ key }}</th>
          <th style="border:1px solid #D3D3D3;" class="text-right">{{ value || '--'}}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="Object.keys(tagsData).length === 0">
          <td style="border:1px solid #D3D3D3;" class="text-right">{{'--'}}</td>
          <td style="border:1px solid #D3D3D3;" class="text-right">{{'--'}}</td>
        </tr>
        <tr style="border-top:2px solid;">
          <td style="border:1px solid #D3D3D3;" class="text-right">{{$t('cabinet.total')}}</td>
          <td style="border:1px solid #D3D3D3;" class="text-right">{{ getTotalTags }}</td>
        </tr>
        </tbody>
      </v-simple-table>
    </div>
  </template>

  <script>


  export default {
    data: () => ({
      totalTags: 0,
    }),
    props: {
      tagsData: {
        require: true
      }
    },
    computed: {
      getTotalTags() {
        this.totalTags = 0;
        const keys = Object.keys(this.tagsData);
        // eslint-disable-next-line no-restricted-syntax
        for (const key of keys) {
          this.totalTags += parseFloat(this.tagsData[key] || 0);
        }
        return this.totalTags;
      },
    }
  };
  </script>

  <style></style>
