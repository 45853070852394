<template>
  <VDialog
    max-width="600px"
    content-class="cross__dialog"
    :transition="$dialogTransition"
    v-model="visibility"
    :persistent="changed"
    @click:outside="showConfirmDialog"
  >
    <VCard class="px-1 py-1">
      <div class="cross__inner">
        <VBtn fab small elevation="0" @click="onClose()">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </div>
      <v-progress-linear
        v-if="loading"
        absolute
        top
        indeterminate
        color="success"
      ></v-progress-linear>
      <v-card-title>
        {{ getDialogTitle }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              v-model="inputPlan.name"
              :label="$t('form.name')"
              color="success"
              dense
              :maxLength="30"
              hide-details="auto"
              :error-messages="nameErrors"
              @keypress="validationForTitle($event)"
              @paste="validationForTitle($event)"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              v-model="inputPlan.license_key"
              :label="$t('crm.license_key')"
              color="success"
              dense
              :maxLength="30"
              hide-details="auto"
              :error-messages="keyErrors"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              v-model="inputPlan.pin"
              :label="$t('crm.pin')"
              color="success"
              dense
              :maxLength="30"
              hide-details="auto"
              :error-messages="pinErrors"
              @keypress="validationForNumbers($event)"
              @paste="validationForNumbers($event)"
            />
          </v-col>
          <v-col cols="12">
            <VTextarea
              outlined
              v-model="inputPlan.description"
              :label="$t('form.description')"
              color="success"
              dense
              no-resize
              counter
              maxlength="125"
              rows="3"
              hide-details="auto"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row no-gutters justify="space-between">
          <v-col cols="6" class="pr-1">
            <VBtn dark block class="br-10 text-transform-none" @click="showConfirmDialog">{{
              $t("btn.cancel")
            }}</VBtn>
          </v-col>
          <v-col cols="6" class="pl-1">
            <VBtn
              block
              v-if="type === 'create'"
              class="success-bg text-white text-transform-none br-10"
              :disabled="loading || !changed"
              @click="onCreate()"
              >{{ $t("btn.create") }}</VBtn
            >
            <VBtn
              block
              v-else-if="type === 'edit'"
              class="success-bg text-white text-transform-none br-10"
              :disabled="loading || !changed"
              @click="onUpdate()"
              >{{ $t("btn.update") }}</VBtn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </VCard>
    <ConfirmLeftDialog
      :visible="visibleConfirm"
      @close="visibleConfirm = false"
      @delete="onCancel"
    />
  </VDialog>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import { required } from "vuelidate/lib/validators";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";
import crmFormsService from "@/services/request/crm/crmForms";
import notifications from "@/mixins/notifications";
import { validationForNumbers, validationForTitle } from "@/mixins/helpers";

export default {
  data: () => ({
    loading: false,
    changed: false,
    visibleConfirm: false,
    inputPlan: {},
    copyPlan: {}
  }),
  mixins: [notifications],
  components: {
    ConfirmLeftDialog
  },
  props: {
    visible: {
      required: true,
      type: Boolean
    },
    boardItem: {
      required: true,
      type: Object
    },
    item: {
      required: true,
      type: Object
    },
    type: {
      required: true
    }
  },
  validations: {
    inputPlan: {
      name: { required },
      license_key: { required },
      pin: { required }
    }
  },
  mounted() {
    this.onMounted();
  },
  methods: {
    validationForNumbers,
    validationForTitle,
    onClose() {
      this.visibility = false;
    },
    onCancel() {
      this.$emit("close");
      this.$v.$reset();
    },
    showConfirmDialog() {
      this.changed ? (this.visibleConfirm = true) : this.onCancel();
    },
    async onCreate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        try {
          const formData = new FormData();
          formData.append("name", this.inputPlan.name);
          if (this.inputPlan.description) {
            formData.append("description", this.inputPlan.description);
          }
          formData.append("key", this.inputPlan.license_key);
          formData.append("pin", this.inputPlan.pin);
          formData.append("uuid_board", this.boardItem.uuid);
          await crmFormsService.addCashier(formData);
          this.$v.$reset();
          this.setSuccessNotification(this.$t("supply.successfully_created"));
          this.$emit("cashier_added");
          this.visibility = false;
        } catch (e) {
          console.log(e);
        } finally {
          this.loading = false;
        }
      }
    },
    async onUpdate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        try {
          const formData = new FormData();
          const changedData = this.$getChangedData(this.inputPlan, this.copyPlan);
          changedData.forEach(data => {
            formData.append(data.field, data.value);
          });
          formData.append("uuid", this.inputPlan.uuid);
          await crmFormsService.updateCashier(formData);
          this.$v.$reset();
          this.setSuccessNotification(this.$t("profile.successfully_updated"));
          this.$emit("cashier_added");
          this.visibility = false;
        } catch (e) {
          console.log(e);
        } finally {
          this.loading = false;
        }
      }
    },
    onMounted() {
      this.loading = true;
      if (this.type === "edit") {
        this.inputPlan = { ...this.item };
        this.copyPlan = { ...this.item };
      }
      this.loading = false;
    }
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        return this.$emit("close");
      }
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.inputPlan.name.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.inputPlan.name.required && errors.push(this.$t("form.errors.NameRequired"));
      return errors;
    },
    keyErrors() {
      const errors = [];
      if (!this.$v.inputPlan.license_key.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.inputPlan.license_key.required && errors.push(this.$t("crm.license_key_required"));
      return errors;
    },
    pinErrors() {
      const errors = [];
      if (!this.$v.inputPlan.pin.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.inputPlan.pin.required && errors.push(this.$t("crm.pin_required"));
      return errors;
    },
    getDialogTitle() {
      switch (this.type) {
        case "create":
          return this.$t("crm.add_cashier");
        case "edit":
          return this.$t("crm.update_cashier");
        default:
          return "--";
      }
    },
    ...mapGetters(["currentLanguage"])
  },
  watch: {
    inputPlan: {
      deep: true,
      handler() {
        this.changed = !_.isEqual(this.copyPlan, this.inputPlan);
      }
    }
  }
};
</script>
