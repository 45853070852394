<template>
  <div>
    <v-simple-table
      class="my-table"
      :dense="!isLarge"
      v-if="!$vuetify.breakpoint.smAndDown"
      :height="getTableHeight"
      fixed-header
    >
      <thead>
        <tr>
          <th class="text-center"> <span class="text-h6" style="opacity: 0.3;">#</span></th>
          <th class="text-center"><VIcon dense>mdi-cogs</VIcon></th>
          <th>
            <div class="header" style="min-width: 75px">
              <VTextField
                v-on:keypress="validationForNumbers($event)"
                @paste="validationForNumbers($event)"
                solo
                :placeholder="'№'"
                dense
                hide-details
                v-model="filterProps.number"
                clearable />
            </div>
          </th>
          <th>
            <div class="header">
              <VTextField
                v-on:keypress="validationForNumbers($event)"
                @paste="validationForNumbers($event)"
                solo
                :placeholder="$t('table.ttn')"
                dense
                hide-details
                v-model="filterProps.ttn"
                clearable />
            </div>
          </th>
          <th>
            <div class="header">
              <VTextField
                v-on:keypress="validationForPhoneNumber($event)"
                @paste="validationForPhoneNumber($event)"
                solo
                :placeholder="$t('table.phone')"
                dense
                hide-details
                v-model="filterProps.delivery_address_details__phone"
                clearable
              />
            </div>
          </th>
          <th>
            <div class="header">
              <VTextField
                v-on:keypress="validationForName($event)"
                @paste="validationForName($event)"
                solo
                :placeholder="$t('table.name')"
                dense
                hide-details
                v-model="filterProps.delivery_address_details__name"
                clearable
              />
            </div>
          </th>
          <th>
            <div class="header">
              <VTextField
                v-on:keypress="validationForSurname($event)"
                @paste="validationForSurname($event)"
                solo
                :placeholder="$t('table.surname')"
                dense
                hide-details
                v-model="filterProps.delivery_address_details__surname"
                clearable
              />
            </div>
          </th>
          <th>
            <div class="header">
              <VTextField solo :placeholder="$t('table.sum')" dense hide-details disabled v-model="filterProps.price" />
            </div>
          </th>
          <th>
            <div class="header">
              <VTextField solo :placeholder="$t('table.status')" dense hide-details disabled v-model="filterProps.product" clearable />
            </div>
          </th>

          <th>
            <div class="header">
              <v-menu
                ref="menuFirstDate"
                v-model="dateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="text-caption"
              >
                <template v-slot:activator="{ on, attrs }">
                  <VTextField
                    solo
                    :placeholder="$t('form.time_created')"
                    v-model="dateRangeText"
                    readonly
                    append-icon="mdi-calendar"
                    dense
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                    style="width: 220px"
                  >
                    <template v-slot:append-outer>
                      <v-slide-x-reverse-transition>
                        <VIcon
                          style="position: relative; bottom: 4px;"
                          @click="onCancelInterval"
                          v-if="time_created_period.length >= 1"
                        >mdi-close</VIcon
                        >
                      </v-slide-x-reverse-transition>
                    </template>
                  </VTextField>
                </template>
                <v-date-picker
                  v-model="time_created_period"
                  range
                  no-title
                  scrollable
                  :locale="currentLanguage.key"
                  min="2021"
                  :max="new Date().toISOString().substr(0, 10)"
                  @input="onChangeDatePeriod"
                >
                </v-date-picker>
              </v-menu>
            </div>
          </th>
          <th class="text-center">
            <v-tooltip bottom min-width="200" max-width="200" LEFT content-class="text-center">
              <template v-slot:activator="{ on, attrs }">
                <VIcon
                  @click="onChangeCallRequired"
                  class="mr-1"
                  :color="filterProps.is_call_required === '1' ? 'primary' : 'grey'"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-phone
                </VIcon>
              </template>

              {{ filterProps.is_call_required === "1" ? $t('btn.needs_a_call_center') : $t('btn.all') }}
            </v-tooltip>
          </th>
          <th class="text-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <VIcon
                  @click="onChangeType"
                  class="mr-1"
                  :color="filterProps.type === 'out' ? 'green' : 'grey'"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-truck-delivery
                </VIcon>
              </template>
              {{ $t('orderDialog.send')}}
            </v-tooltip>
          </th>
          <th class="text-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <VIcon
                  class="mr-1"
                  v-bind="attrs"
                  v-on="on"
                  color="grey"
                >
                  mdi-receipt-text
                </VIcon>
              </template>
              {{ $t('orderDialog.checkbox_integration')}}
            </v-tooltip>
          </th>
          <th>
            <div class="header">
              <VSelect
                dense
                :placeholder="$t('table.delivery_status')"
                :items="Object.values(states)"
                :item-text="getStatusText"
                :item-value="'id'"
                hide-details
                solo
                v-model="filterProps.id_state"
                clearable
                class="text-caption"
                :menu-props="{ bottom: true, offsetY: true }"
              >
                <template v-slot:item="{ item }">
                  <div class="d-flex align-center justify-content-start ">
                    <StatusCircle :status-name="item.id" :type="'supply'" class="pb-1" />
                    <div class="elispsis">
                      {{ getStatusText(item) }}
                    </div>
                  </div>
                </template>
              </VSelect>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <CrmItemRow
          v-for="item in items"
          :key="item.uuid"
          :item="item"
          :colors="colors"
          :uuid_board="uuid_board"
          :class="{ 'last-active-row': item.uuid == activeItem }"
          @toggleRow="toggleRow"
        />
        <tr v-if="!loadMoreDisabled">
          <td colspan="14" class="observer">
            <v-progress-linear
              v-if="isLoadingFilter"
              indeterminate
              color="primary"
            ></v-progress-linear>
            <div
              v-observer="{ nextPage: nextPage }"
              class="observer"
              v-if="!loadMoreDisabled && !loadingItems"
            ></div>
          </td>
        </tr>

        <tr v-if="!items.length">
          <td colspan="14" :style="`background: ${$vuetify.theme.dark ? '#545252' : '#fafafa'} color: ${$vuetify.theme.dark ? '#fafafa' : '#545252'}`" id="no_data_td_crm">
            <div class="text-center">
              <h3>{{ $t("table.sorry_no_inсome") }}</h3>
            </div>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <div v-else>
      <CrmFilter :filterPropsExact="filterProps" @onApplyFilter="onApplyFilter" />
      <crm-row-item-card
        v-for="crmItem in items"
        :key="crmItem.uuid"
        :supply-item="crmItem"
        :colors="colors"
        :uuid_board="uuid_board"
      />
      <div
        v-observer="{ nextPage: nextPage }"
        class="observer"
        v-if="!loadMoreDisabled && !loadingItems"
      ></div>
      <div v-if="!items.length && !loadingItems">
        <div class="text-center">
          <h3>{{ $t("table.sorry_no_inсome") }}</h3>
        </div>
      </div>
    </div>

    <!-- <div class="text-center mt-3">
      <VBtn
        depressed
        color="blue"
        outlined
        dark
        v-if="!loadMoreDisabled"
        @click="nextPage"
        class="br-10"
      >
        Завантажити більше
      </VBtn>
    </div> -->
  </div>
</template>

<script>
import _ from "lodash";
import notifications from "../../../mixins/notifications";
import loader from "../../../mixins/loader";
import crmService from "../../../services/request/crm/crmService";
import CrmItemRow from "./CrmItemRow.vue";
import EventBus from "../../../events/EventBus";
import SizeUi from "../../../mixins/SizeUi";
import {mapActions, mapGetters} from "vuex";
import CrmRowItemCard from "./sm/CrmRowItemCard.vue";
import CrmFilter from "./sm/CrmFilter.vue";
import depotService from "@/services/request/depot/depotService";
import StatusCircle from "@/components/move-list/StatusCircle.vue";
import {
  validationForName,
  validationForNumbers,
  validationForPhoneNumber,
  validationForSurname
} from "@/mixins/helpers";
import * as getterTypes from "@/store/modules/pageLoadTime/types/getters";
import * as actionTypes from "@/store/modules/pageLoadTime/types/actions";

export default {
  name: "CrmRowList",
  components: {
    CrmItemRow,
    CrmRowItemCard,
    CrmFilter,
    StatusCircle
  },
  mixins: [loader, notifications, SizeUi],
  data: () => ({
    dateMenu: false,
    filterProps: {
      number: "",
      ttn: "",
      delivery_address_details__name: "",
      delivery_address_details__surname: "",
      delivery_address_details__phone: "",
      time_created: "",
      date_created_begin: "",
      date_created_end: "",
      price: "",
      product: "",
      address: "",
      type: false,
      filter_ready_to_deliver: false,
      is_call_required: null,
      id_state: null
    },
    dateRangeText: '',
    time_created_period: [],
    page: 1,
    perPageList: [5, 10, 15],
    firstNumber: "",
    secondNumber: "",
    total: 12,
    items: [],
    loadMoreDisabled: false,
    isLoadingFilter: false,
    loadingItems: true,
    windowHeight: document.documentElement.clientHeight,
    states: [],
    activeItem: null,
    loadTime: 0
  }),
  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowHeight);
  },
  async mounted() {
    window.addEventListener("resize", this.getWindowHeight);
    // // orderDialog('colors-row', this.colors);
    await this.updateItems();
    this.states = await depotService.getAllStates({
      type: "DELIVERY_OUT"
    });
    EventBus.$on("crm-items-reset", () => {
      this.updateItems(true);
    });
    this.checkLoadTime();
  },
  methods: {
    validationForName,
    validationForSurname,
    validationForPhoneNumber,
    validationForNumbers,
    ...mapActions('pageLoadTime', {
      setPageLoadTime: actionTypes.SET_PAGE_LOAD_TIME,
    }),
    onCancelInterval() {
      this.time_created_period = [];
      this.filterProps.date_created_begin = "";
      this.filterProps.date_created_end = "";
      this.filterProps.time_created = "";
      this.dateRangeText = "";
      this.onApplyFilter(this.filterProps);
    },
    checkLoadTime() {
      const endTime = performance.now();
      this.loadTime = endTime - this.startTime;
      this.setPageLoadTime(this.loadTime)
    },
    onChangeDatePeriod() {
      if (this.time_created_period.length === 2) {
        if(this.time_created_period[0] === this.time_created_period[1]) {
          this.filterProps.time_created = this.time_created_period[0]
          this.dateRangeText = this.time_created_period[0];
          this.time_created_period.splice(1, 1);
        } else {
          this.filterProps.time_created = "";
          const firstDate = new Date(this.time_created_period[0]);
          const secondDate = new Date(this.time_created_period[1]);
          if (firstDate > secondDate) {
            this.filterProps.date_created_begin = this.time_created_period[1];
            this.filterProps.date_created_end = this.time_created_period[0];
            this.dateRangeText = `${this.time_created_period[1]} ~ ${this.time_created_period[0]}`;
          } else {
            this.filterProps.date_created_begin = this.time_created_period[0];
            this.filterProps.date_created_end = this.time_created_period[1];
            this.dateRangeText = this.time_created_period.join(' ~ ');
          }
        }
        this.onApplyFilter(this.filterProps);
      } else if (this.time_created_period.length === 1) {
        this.filterProps.date_created_begin = "";
        this.filterProps.date_created_end = "";
        this.dateRangeText = this.time_created_period[0];
        this.filterProps.time_created = this.time_created_period[0]
        this.onApplyFilter(this.filterProps);
      }
    },
    toggleRow(event) {
      this.activeItem = event;
    },
    getStatusText(item) {
      if(this.currentLanguage.key === "en") {
        return item.name;
      }
      return item[`${this.currentLanguage.key}_name`];
    },
    getWindowHeight: _.debounce(function() {
      this.windowHeight = document.documentElement.clientHeight;
    }, 500),
    onChangeType() {
      if (this.filterProps.type === "out") {
        this.filterProps.type = undefined;
      } else {
        this.filterProps.type = "out";
      }
    },
    onChangeCallRequired() {
      if (this.filterProps.is_call_required === "1") {
        this.filterProps.is_call_required = null;
      } else {
        this.filterProps.is_call_required = "1";
      }
    },
    onApplyFilter(filterItems) {
      this.filterProps = {
        ...filterItems
      };
      this.updateItems();
    },
    resetPagination() {
      this.page = 1;
    },
    async updateItems(updated) {
      if (!updated) {
        this.resetPagination();
      }
      try {
        const params = {};
        Object.keys(this.filterProps).forEach(key => {
          if (this.filterProps[key]) {
            params[key] = this.filterProps[key];
          }
        });

        if (this.uuid_filter !== null) {
          params.uuid_user_state = this.uuid_filter;
        }

        params.offset = 0;
        params.limit = updated ? this.largeLimit : this.perPage;
        if(this.uuid_user_assigned_to && this.uuid_user_assigned_to !== true) {
          params.uuid_user_assigned_to = this.uuid_user_assigned_to;
        }
        const orderList = await crmService.getCrmOrderList({
          uuid_board: this.uuid_board,
          ...params
        });
        this.items = orderList.object;
        this.items.forEach(function(item, index) {
          item.index = index + 1;
        });
        const partsIn = orderList.message.split('total: ');
          if (partsIn.length === 2 && orderList.message != 'List of objects is empty') {
            this.total = parseInt(partsIn[1], 10);
            this.$emit('TotalMessage', this.total);
          } else {
            this.total = 0;
            this.$emit('TotalMessage', this.total);
          }
        this.loadMoreDisabled = this.items.length < (this.perPage * this.page - 1);
        this.loadingItems = false;
      } catch (e) {
        this.loadingItems = false;
        console.log(e)
      }
    },
    getPaginationParams() {
      return {
        limit: this.perPage,
        offset: (this.page - 1) * this.perPage,
        uuid_board: this.uuid_board
      };
    },
    async emitPageChange() {
      try {
        this.loadingItems = true;
        const params = this.getPaginationParams();
        Object.keys(this.filterProps).forEach(key => {
          if (this.filterProps[key]) {
            params[key] = this.filterProps[key];
          }
        });
        if (this.uuid_filter !== null) {
          params.uuid_user_state = this.uuid_filter;
        }
        if(this.uuid_user_assigned_to && this.uuid_user_assigned_to !== true) {
          params.uuid_user_assigned_to = this.uuid_user_assigned_to;
        }
        if(this.uuid_board) {
          params.uuid_board = this.uuid_board;
        }
        const orderList = await crmService.getCrmOrderList(params);
        this.items.push(...orderList.object);
        this.items.forEach(function(item, index) {
          item.index = index + 1;
        });
        this.loadMoreDisabled = orderList.object.length < this.perPage;
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingItems = false;
      }
    },
    async nextPage() {
      this.isLoadingFilter = true;
      this.page += 1;
      await this.emitPageChange();
      this.isLoadingFilter = false;
    }
  },
  computed: {
    ...mapGetters('pageLoadTime', {
      startTime: getterTypes.GET_START_TIME,
    }),
    ...mapGetters(["currentLanguage"]),
    dragOptions() {
      return {
        disabled: false,
        ghostClass: "ghost",
        handle: ".handle"
      };
    },
    getTableHeight() {
      return this.windowHeight - 195 + "px";
    },
    largeLimit() {
      const pageLimit = this.perPage * this.page;
      if (pageLimit >= 200) {
        this.page = this.pageBeforeLimit - 1;
        return 200;
      }
      return this.perPage * this.page;
    },
    NextYear() {
      const event = new Date().toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }).substr(0, 10);

      const [month, day, year] = event.split(/[\s,\/]+/);

      const formattedEvent = `${year}-${month}-${day}`;

      console.log(formattedEvent);
      return formattedEvent;
    },
    rowHeight() {
      if (this.isLarge) {
        return 48;
      } else {
        return 35;
      }
    },
    perPage() {
      return Math.ceil((window.innerHeight - 150) / this.rowHeight);
    }
  },
  props: {
    uuid_filter: String,
    uuid_board: String,
    uuid_user_assigned_to: String,
    colors: {
      required: true
    },
    chosenFilter: {
      required: false
    }
  },
  watch: {
    uuid_filter(e) {
      if (!this.filterProps.filter_ready_to_deliver) {
        this.updateItems();
      }
    },
    uuid_user_assigned_to: "updateItems",
    uuid_board: "updateItems",
    filterProps: {
      deep: true,
      handler(e) {
        this.$scrollTop({}, document.querySelector(".v-data-table__wrapper"));
        this.updateItems();
      }
    },
    chosenFilter: {
      deep: true,
      handler(chosenFilter) {
        const readyToDeliverValue = "filter_ready_to_deliver";
        this.filterProps[readyToDeliverValue] = chosenFilter;
      }
    },
  }
};
</script>

<style lang="scss">
.v-date-picker-table {
  height: 100%;
}
#no_data_td_crm {
  border: none;
}
.ghost {
  opacity: 0.5;
  background: gray !important;
}
.crm-order {
  &__null {
    padding: 50px 0;
  }
  &__header {
    border-bottom: 1px solid #f2f2f2;
    .td {
      padding: 4px;
      text-align: center;
      input {
        border: 1px solid #eeeded;
        padding: 4px;
        &:disabled {
          background-color: #eeeded;
        }
      }
      span {
        font-family: Roboto;
        margin-bottom: 15px;
        display: block;
        color: #676767;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
      }
    }
  }
  &__data {
    &-item {
      &:nth-child(2n - 1) {
        background: #f8f8f8;
      }
    }
  }
  .td {
    &:nth-child(1) {
      width: 10%;
    }
    &:nth-child(2) {
      width: 15%;
    }
    &:nth-child(3) {
      width: 9%;
    }
    &:nth-child(4) {
      width: 9%;
    }
    &:nth-child(5) {
      width: 9%;
    }
    &:nth-child(6) {
      width: 9%;
    }
    &:nth-child(7) {
      width: 18%;
    }
    &:nth-child(8) {
      width: 18%;
    }
    &:nth-child(9) {
      width: 4%;
    }
  }
}
.last-active-row {
  border-left: 1.3px solid #919191 !important;
  font-weight: 500 !important;
  box-shadow: 0 1.3px 0 #919191 inset,
              0 -1.3px 0 #919191 inset;
  -webkit-box-shadow: 0 1.3px 0 #919191 inset,
              0 -1.3px 0 #919191 inset;

  td:last-child {
    border-right: 1.3px solid #919191;
  }
}
</style>
