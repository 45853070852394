<template>
  <div class="section-crm">
    <v-row class="align-center mt-1" >
      <!-- <VCol cols="12"   v-if="boardSetting.list.length && $vuetify.breakpoint.smAndDown">
        <VAutocomplete
          dense
          :items="boardSetting.list"
          outlined
          :item-value="'uuid'"
          :item-text="'name'"
          v-model="boardsItem"
          return-object
          :hide-details="true"
          @change="changeBoard"
          :menu-props="{bottom:true,offsetY:true}"
        />
      </VCol> -->
      <VCol :cols="$vuetify.breakpoint.smAndDown ? 12 : 3" class="d-flex">
        <VSelect
          dense
          :items="boardSetting.list"
          outlined
          :item-value="'uuid'"
          :item-text="'name'"
          v-model="boardsItem"
          return-object
          :hide-details="true"
          @keydown.native="onKeyPressSelect"
          @change="changeBoard"
          :menu-props="{ bottom: true, offsetY: true }"
          :label="$t('crm.crmBoard')"
          :no-data-text="$t('table.noData')"
          color="success"
          item-color="success"
        >
          <template slot="item" slot-scope="{ item }">
            <v-row no-gutters justify="space-between" align="center">
              {{ item.name }}
              <div>
                <VIcon v-if="item.is_default == '1'" color="yellow darken-2" small>mdi-star</VIcon>
                <VIcon
                  v-if="item.uuid_user_created != getUser?.uuid"
                  class="ml-2"
                  color="deep-purple"
                  small
                  >mdi-share-variant</VIcon
                >
              </div>
            </v-row>
          </template>
          <template slot="selection" slot-scope="{ item }">
            <div class="mr-1">
              {{ item.name }}
            </div>
            <VIcon v-if="item.is_default == '1'" color="yellow darken-2" small>mdi-star</VIcon>
            <VIcon v-if="item.uuid_user_created != getUser?.uuid" color="deep-purple" small
              >mdi-share-variant</VIcon
            >
          </template>
        </VSelect>
      </VCol>

      <VCol :cols="$vuetify.breakpoint.smAndDown ? 12 : 'auto'">
        <div class="d-flex align-center" :class="$vuetify.breakpoint.smAndDown ? 'justify-center' : ''">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <VBtn
                class="ml-2 btn-settingBoard success-bg text-white text-transform-none br-10"
                @click="boardSetting.visible = !boardSetting.visible"
                v-bind="attrs"
                v-on="on"
              >
                <VIcon>mdi-cog</VIcon>
              </VBtn>
            </template>
            <span>{{ $t("crm.settingtsTitle") }}</span>
          </v-tooltip>
          <CrmForms
            class="ml-2"
            :boardList="boardSetting.list"
            :boardUuid="boardsItem"
            :stateUuid="tab[activeTab]"
            v-if="isBoardCreator"
          ></CrmForms>
          <StatsComponent
            class="ml-2"
            :boardUuid="boardsItem"
            :states="tab"
            :uuid_board="this.boardsItem.uuid"
            v-if="isAllowStatisticReview"
            :isAllowStatisticReview="isAllowStatisticReview"
          ></StatsComponent>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <VBtn
                class="text-white success-bg btn-settingBoard text-transform-none br-10 ml-2"
                @click="suitsVisible = true"
                v-bind="attrs"
                v-on="on"
                :disabled="!uuid_board"
              >
                <VIcon>mdi-sitemap-outline</VIcon>
              </VBtn>
            </template>
            <span>{{ $t("crm.suit.title") }}</span>
          </v-tooltip>
          <!-- <SuitsComponent
            class="ml-2"
            :boardUuid="boardsItem"
            :states="tab"
            :uuid_board="this.boardsItem.uuid"
            :isAllowStatisticReview="isAllowStatisticReview"
            :isAllowEditSuits="isAllowEditSuits"
          ></SuitsComponent> -->
          <v-slide-x-reverse-transition>
            <ShareUser
              class="ml-2"
              :boardList="boardSetting.list"
              :boardUuid="boardsItem"
              :stateUuid="tab[activeTab]"
              v-if="isBoardCreator"
            ></ShareUser>
          </v-slide-x-reverse-transition>
        </div>
      </VCol>
      <v-spacer></v-spacer>
      <VCol
        :cols="$vuetify.breakpoint.smAndDown ? 12 : 'auto'"
      >
        <v-row class="d-flex text-right pa-3 pr-2" :class="$vuetify.breakpoint.smAndDown ? 'justify-center' : 'justify-end'">
          <div style="width: 250px" class="d-flex justify-end text-center">
            <VSelect
              v-if="showUserSelector"
              dense
              outlined
              :placeholder="'Select user'"
              :items="assignedUsers"
              :item-text="formatUserName"
              item-value="uuid_user"
              v-model="choosenAssignedUser"
              color="success"
              style="height: 20px"
              item-color="success"
              hide-details
            >
              <template v-slot:item="{ item }">
                <div class="d-flex align-center justify-content-start">
                  <div>
                    {{ formatUserName(item) }}
                  </div>
                </div>
              </template>
            </VSelect>
                <VBtn v-if="!showUserSelector" @click="getAssignedUsers" class="mr-2" icon >
                  <VIcon>mdi-account</VIcon>
                </VBtn>
                <VBtn v-if="showUserSelector" @click="closeSelector" class="mr-2" icon >
                  <VIcon>mdi-close</VIcon>
                </VBtn>
          </div>
          <v-tooltip bottom v-if="setSpeedLeadData && permissions.can_see_leads">
            <template v-slot:activator="{ on, attrs }">
              <VBtn
                v-bind="attrs"
                v-on="on"
                class="btn-edit mr-2 br-10"
                :color="speedLeadConfig[setSpeedLeadData]?.btn_color"
                :outlined="speedLeadConfig[setSpeedLeadData]?.outlined"
              >
                <VIcon :color="speedLeadConfig[setSpeedLeadData]?.icon_color">
                  {{ speedLeadConfig[setSpeedLeadData]?.icon }}
                </VIcon>
              </VBtn>
            </template>
            {{ `${$t("crm.speed")}: ${speedLeadValue} ${$t("crm.leedHour")}` }}
          </v-tooltip>

          <v-tooltip bottom v-if="hasBoards === true">
            <template v-slot:activator="{ on, attrs }">
              <VBtn
                v-bind="attrs"
                v-on="on"
                class="btn-edit mr-2 br-10"
                :color="chosenFilter ? 'primary' : 'secondary lighten-5'"
                v-model="chosenFilter"
                @click="chosenFilter = !chosenFilter"
              >
                <VIcon>
                  mdi-truck-fast-outline
                </VIcon>
              </VBtn>
            </template>
            {{ $t('crm.deliveries_ready_to_ship')}}
          </v-tooltip>
          <VBtn
            class="success-bg text-white text-transform-none br-10"
            @click="openItemDialog('add', {})"
            :disabled="hasBoards === false"
          >
            <VIcon left>mdi-plus</VIcon>
            {{ $t("btn.addOrder") }}
          </VBtn>
        </v-row>
      </VCol>
    </v-row>
    <v-row>
      <VCol class="pt-0 pb-2">
        <v-row justify="start">
          <VCol :cols="amountOfCols">
            <VTabs
              v-if="tab.length"
              class="crm-tab"
              active-class="active"
              background-color="transparent"
              slider-color="transparent"
              v-model="activeTab"
              show-arrows
              :style="
                tab[activeTab]?.color !== '#FFF'
                  ? `border-bottom-color: ${tab[activeTab]?.color};`
                  : ''
              "
            >
              <Draggable
                v-model="tab"
                class="crm-tabDraggable"
                @change="moveTab"
                draggable=".item"
                v-if="isAllowEditState"
                ref="tabElement2"
              >
                <VTab
                  @click="updateItems(item.uuid, item.noEdit)"
                  v-for="(item, index) in tab"
                  :key="index"
                  :class="{
                    black: item.color === '#000',
                    white: item.color === '#FFF',
                    item: item.uuid !== '00000000-0000-0000-0000-000000000000'
                  }"
                  class="ma-0"
                  :ripple="false"
                >
                  <div
                    class="tab-text"
                    :style="`background: ${item?.color}; color: ${item.uuid === '00000000-0000-0000-0000-000000000000' && $vuetify.theme.dark ? '#717171' : ''};`"
                    @dblclick="dblClick(item)"
                    v-if="item.uuid_user"
                  >
                    {{ item.name }}
                  </div>
                  <div
                    class="tab-edit"
                    :style="{ border: '1px solid ' + (item.name.length ? '#eeeded' : '#ff0000') }"
                    v-if="!item.uuid_user && !item.noEdit"
                    v-click-outside="outSideEditTab"
                  >
                    <input
                      maxlength="20"
                      v-model="item.name"
                      v-on:keyup.enter="outSideEditTab"
                      :style="
                        `width: ${item.name.length + 4}ch; color: ${
                          isDark? '#fafafa' : '#545252'
                        }`
                      "
                    />

                    <VMenu offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <div class="tab-edit-color" v-bind="attrs" v-on="on">
                                <span :style="`background: ${item?.color}`"></span>
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.71005 11.71L11.3001 14.3C11.6901 14.69 12.3201 14.69 12.7101 14.3L15.3001 11.71C15.9301 11.08 15.4801 10 14.5901 10H9.41005C8.52005 10 8.08005 11.08 8.71005 11.71Z"
                                    fill="#676767"
                                  />
                                </svg>
                              </div>
                            </template>
                            <span>{{ $t("crm.color_picker") }}</span>
                          </v-tooltip>
                        </div>
                      </template>
                      <ColorPicker v-model="item.color"></ColorPicker>
                    </VMenu>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <VIcon
                          v-bind="attrs"
                          v-on="on"
                          dense
                          @click="updateItemCallState(item)"
                          :color="item.is_call_state === '1' ? 'primary' : 'grey'"
                        >
                          {{ item.is_call_state === "1" ? "mdi-phone" : "mdi-phone-off" }}
                        </VIcon>
                      </template>
                      <span>{{
                        item.is_call_state === "1"
                          ? $t("crm.call_center_required")
                          : $t("crm.call_center_not_required")
                      }}</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <VIcon v-bind="attrs" v-on="on" @click="outSideEditTab">
                          mdi-close
                        </VIcon>
                      </template>
                      <span>{{ $t("btn.close") }}</span>
                    </v-tooltip>
                  </div>
                </VTab>
              </Draggable>
              <VTab
                v-else
                @click="updateItems(item.uuid, item.noEdit)"
                v-for="(item, index) in tab"
                :key="index"
                :class="{
                  black: item.color === '#000',
                  white: item.color === '#FFF',
                  item: item.uuid !== '00000000-0000-0000-0000-000000000000'
                }"
                class="ma-0"
                :ripple="false"
              >
                <div
                  class="tab-text"
                  :style="`background: ${item?.color};`"
                  @dblclick="dblClick(item)"
                  v-if="item.uuid_user"
                >
                  {{ item.name }}
                </div>
                <div
                  class="tab-edit"
                  :style="{ border: '1px solid ' + (item.name.length ? '#eeeded' : '#ff0000') }"
                  v-if="!item.uuid_user && !item.noEdit"
                  v-click-outside="outSideEditTab"
                >
                  <input
                    maxlength="20"
                    v-model="item.name"
                    v-on:keyup.enter="outSideEditTab"
                    :style="
                      `width: ${item.name.length + 4}ch; color: ${
                        isDark ? '#fafafa' : '#545252'
                      }`
                    "
                  />
                  <VMenu offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                      <div class="tab-edit-color" v-bind="attrs" v-on="on">
                        <span :style="`background: ${item?.color}`"></span>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.71005 11.71L11.3001 14.3C11.6901 14.69 12.3201 14.69 12.7101 14.3L15.3001 11.71C15.9301 11.08 15.4801 10 14.5901 10H9.41005C8.52005 10 8.08005 11.08 8.71005 11.71Z"
                            fill="#676767"
                          />
                        </svg>
                      </div>
                    </template>
                    <ColorPicker v-model="item.color"></ColorPicker>
                  </VMenu>
                  <VIcon
                    dense
                    @click="updateItemCallState(item)"
                    :color="item.is_call_state === '1' ? 'primary' : 'grey'"
                  >
                    {{ item.is_call_state === "1" ? "mdi-phone" : "mdi-phone-off" }}
                  </VIcon>
                  <VIcon @click="outSideEditTab">
                    mdi-close
                  </VIcon>
                </div>
              </VTab>
            </VTabs>
          </VCol>
          <VCol cols="auto">
            <v-edit-dialog ref="myColorEditDialog">
              <div class="crm-tab__add">
                <VIcon :disabled="loadingAddNewTab" :loading="loadingAddNewTab">mdi-plus</VIcon>
              </div>
              <template v-if="isAllowEditState" v-slot:input>
                <VCard elevation="0">
                  <v-card-text>
                    <v-row justify="space-around" align="center">
                      <v-text-field
                        maxlength="20"
                        solo
                        dense
                        v-model="nameNewTab"
                        v-on:keyup.enter="onAddTab"
                        :disabled="loadingAddNewTab"
                        hide-details="auto"
                      >
                        <template slot="append">
                          <span class="color-span" :style="`background: ${colorNewTab}`"></span>
                        </template>
                      </v-text-field>
                      <VMenu offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                          <div class="crm-tab__addInput-color" v-bind="attrs" v-on="on">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.71005 11.71L11.3001 14.3C11.6901 14.69 12.3201 14.69 12.7101 14.3L15.3001 11.71C15.9301 11.08 15.4801 10 14.5901 10H9.41005C8.52005 10 8.08005 11.08 8.71005 11.71Z"
                                fill="#676767"
                              />
                            </svg>
                          </div>
                        </template>
                        <ColorPicker v-model="colorNewTab"></ColorPicker>
                      </VMenu>
                    </v-row>
                  </v-card-text>
                </VCard>
              </template>
            </v-edit-dialog>
          </VCol>
        </v-row>
        <div class="section-crm__content">
          <CrmRowList
            class="crm-order"
            v-if="boardsItem.uuid"
            :uuid_filter="uuid_filter"
            :uuid_board="boardsItem.uuid"
            :colors="tab"
            :chosenFilter="chosenFilter"
            :uuid_user_assigned_to="choosenAssignedUser"
            @TotalMessage="TotalMessageHandle"
          >
          </CrmRowList>
        </div>
      </VCol>
    </v-row>
    <v-row
      v-if="isBoardCreator"
      align="center"
      no-gutters
      class="mt-1"
      style="border-top:1px solid #e4e4e4;"
    >
      <VCol cols="auto" class="crm-tag pr-1" style="border-right:2px solid #e4e4e4">
        За день: {{ tagsStats.leads_today }}
      </VCol>
      <VCol cols="auto">
        <VTabs
          style="height:auto"
          v-if="tab.length"
          class="crm-tag"
          slider-color="transparent"
          active-class="active"
          show-arrows
        >
          <VTab
            v-for="tag in tagsStats.tags"
            :key="tag.tag"
            :ripple="false"
            style="border-right:thin solid #e4e4e4"
          >
            <div>{{ tag.tag || "--" }}:{{ tag.amount || 0 }}</div>
          </VTab>
        </VTabs>
      </VCol>
      <v-spacer></v-spacer>
      <VCol cols="auto" class="pr-1">
        <VBtn icon x-small @click="showEditTagTemplate = true">
          <VIcon dense>
            mdi-cog
          </VIcon>
        </VBtn>
      </VCol>
      <VCol cols="auto" class="crm-tag pl-2" style="border-left:2px solid #e4e4e4;">
        {{ $t("cabinet.total") }}: {{ totalMessage || 0 }}
      </VCol>
    </v-row>
    <SettingBoardDialog
      :visible="boardSetting.visible"
      :boardList="boardSetting.list"
      @close="boardSetting.visible = false"
      v-if="boardSetting.visible"
    ></SettingBoardDialog>

    <DialogWrapper
      v-if="boardsItem.uuid"
      :visible="itemDialog.visible"
      :typeDialog="itemDialog.type"
      @close="itemDialog.visible = false"
      settingDialog="crm"
    >
      <OrderDialog
        :isAllowEditState="isAllowEditState"
        :isAllowAssignedUserChange="isAllowAssignedUserChange"
        v-if="itemDialog.visible"
        :user_states="itemDialog.user_states || []"
        :uuidItem="itemDialog.item.uuid"
        :typeDialog="itemDialog.type"
        settingDialog="crm"
        :isAccessDeportInfo="isAccessDeportInfo"
        :isAllowSendOrder="isAllowSendOrder"
      ></OrderDialog>
    </DialogWrapper>
    <SuitsComponent
      v-if="suitsVisible"
      :boardUuid="boardsItem"
      :states="tab"
      :visible="suitsVisible"
      @close="suitsVisible = false"
      :uuid_board="this.boardsItem.uuid"
      :isAllowStatisticReview="isAllowStatisticReview"
      :isAllowEditSuits="isAllowEditSuits"
    ></SuitsComponent>
    <editTagTemplateDialogVue
      :uuid_board="uuid_board"
      :tags="tags"
      :visible="showEditTagTemplate"
      @close="showEditTagTemplate = false"
    />
    <!--    <ConfirmDeleteDialog
      :visible="deleteConfirm.visible"
      @close="closeDeleteTabItemDialog"
      @delete="onDelete"
    />-->
    <!--    :item="itemDialog.item"-->
  </div>
</template>

<script>
import _ from "lodash";
import Draggable from "vuedraggable";
import ClickOutside from "vue-click-outside";
import { mapActions, mapGetters } from "vuex";
import * as actionTypes from "@/store/modules/crm/types/actions";
import * as getterTypes from "@/store/modules/crm/types/getters";
import notifications from "../../mixins/notifications";
import loader from "../../mixins/loader";
import crmService from "../../services/request/crm/crmService";
import user from "../../mixins/user";
import CrmRowList from "./ordersList/CrmRowList.vue";
import OrderDialog from "../orderDialog/OrderDialog.vue";
import DialogWrapper from "../orderDialog/DialogWrapper.vue";
import SettingBoardDialog from "./ordersList/SettingBoardDialog.vue";
import ColorPicker from "../common/ColorPicker.vue";
import CrmForms from "./crmForms/crmForms.vue";
import EventBus from "../../events/EventBus";
import ShareUser from "@/components/crm/ordersList/shareUser.vue";
import StatsComponent from "@/components/crm/stats/StatsComponent.vue";
import SuitsComponent from "@/components/crm/suits/SuitsComponent.vue";
import editTagTemplateDialogVue from "./ordersList/editTagTemplateDialog.vue";
import ConfirmDeleteDialog from "../dialog/ConfirmDeleteDialog.vue";
import theme from "@/mixins/theme";
import * as actionType from "@/store/modules/usersKey/types/actions";
import * as getterType from "@/store/modules/usersKey/types/getters";
import localStorageMixin from "@/mixins/localStorage";

export default {
  name: "CrmComponent",
  components: {
    CrmRowList,
    OrderDialog,
    ColorPicker,
    SettingBoardDialog,
    Draggable,
    CrmForms,
    DialogWrapper,
    ShareUser,
    StatsComponent,
    SuitsComponent,
    editTagTemplateDialogVue,
    ConfirmDeleteDialog
  },
  mixins: [notifications, loader, user,theme, localStorageMixin],
  data: () => ({
    deleteConfirm: {
      visible: false
    },
    colWidth: 0,
    amountOfCols: 'auto',
    showUserSelector: false,
    assignedUsers: [],
    choosenAssignedUser: null,
    menu: false,
    showEditTagTemplate: false,
    tagsStats: [],
    tags: [],
    speedLeadValue: 0,
    loadingAddNewTab: false,
    suitsVisible: false,
    itemDialog: {
      visible: false,
      type: "add",
      item: {},
      user_states: []
    },
    boardSetting: {
      visible: false,
      list: []
    },
    boardsItem: {},
    createPermanentDialog: false,
    activeTab: 0,
    dialogNewTab: false,
    nameNewTab: "",
    uuid_filter: null,
    colorNewTab: "#EEFDFF",
    tab: [],
    chosenFilter: false,
    hasBoards: null,
    editedTab: null,
    totalMessage: "",
    speedLeadConfig: {
      panda: {
        icon: "mdi-panda",
        btn_color: "grey grey-lighten-4",
        icon_color: "white",
        text: "speedLeadText.panda",
        outlined: false
      },
      snail: {
        icon: "mdi-snail",
        btn_color: "orange orange-lighten-3",
        icon_color: "white",
        text: "speedLeadText.snail",
        outlined: false
      },
      koala: {
        icon: "mdi-koala",
        btn_color: "amber amber-lighten-2",
        icon_color: "white",
        text: "speedLeadText.koala",
        outlined: false
      },
      tortoise: {
        icon: "mdi-tortoise",
        btn_color: "green green-lighten-2",
        icon_color: "white",
        text: "speedLeadText.tortoise",
        outlined: false
      },
      horse: {
        icon: "mdi-horse-variant-fast",
        btn_color: "brown brown-lighten-2",
        icon_color: "white",
        text: "speedLeadText.horse",
        outlined: false
      },
      rabbit: {
        icon: "mdi-rabbit",
        btn_color: "deep-orange deep-orange-darken-1",
        icon_color: "white",
        text: "speedLeadText.rabbit",
        outlined: false
      },
      shark: {
        icon: "mdi-shark",
        btn_color: "blue blue-darken-5",
        icon_color: "indigo-darken-1",
        text: "speedLeadText.shark",
        outlined: false
      },
      bat: {
        icon: "mdi-bat",
        btn_color: "black",
        icon_color: "white",
        text: "speedLeadText.bat",
        outlined: false
      }
    }
  }),
  directives: {
    ClickOutside
  },
  watch: {
    'isDark': {
      handler(e)  {
       this.getStateList()
      },
    },
    choosenAssignedUser: {
      handler(){
        this.setUserKey(this.choosenAssignedUser);
        if(this.choosenAssignedUser !== null && this.choosenAssignedUser !== true) {
          this.showUserSelector = true;
        }
      }
    },
    showEditTagTemplate: {
      deep: true,
      handler() {
        if(this.showEditTagTemplate == true) {
          this.getTagsTemplateList();
        }
      }
    }
  },
  beforeDestroy() {
    EventBus.$off("crm-item-edit");
    EventBus.$off("tags-modified");
    EventBus.$off("get-speed-lead");
    EventBus.$off("crm-items-reset");
    EventBus.$off("crm-board-items-reset");
    window.removeEventListener("resize", this.getScreenWidth);
  },
  async mounted() {
    //
    window.addEventListener("resize", this.getScreenWidth);
    this.choosenAssignedUser = this.userKey;
    EventBus.$on("crm-item-edit", item => {
      this.openItemDialog("edit", item);
    });
    EventBus.$on("tags-modified", item => {
      this.getTagsTemplateList();
      this.getTagStats();
    });
    EventBus.$on("get-speed-lead", () => {
      this.getSpeedLead();
    });

    EventBus.$on("crm-items-reset", () => {
      this.getTagsTemplateList();
      this.getTagStats();
    });

    EventBus.$on("crm-board-items-reset", async respons => {
      this.boardSetting.list = await crmService.getCrmBoardsList();
      this.hasBoards = Boolean(this.boardSetting.list);
      if (!this.boardsItem.length) {
        // orderDialog('respons', respons);
        if (respons) {
          this.boardsItem = respons;
          this.setUuidBoard(respons.uuid);
        } else {
          if (this.$route.params.uuid_board) {
            this.setUuidBoard(this.$route.params.uuid_board || "");
          }
          const defaultIndex = this.boardSetting.list.findIndex(item => item.is_default == "1");
          this.boardsItem = this.boardSetting.list[defaultIndex != -1 ? defaultIndex : 0];
          this.$route.params.uuid_board = this.boardsItem.uuid;
        }
        this.getStateList();
      }
    });

    try {
      this.setLoading(true);

      await crmService.getCrmBoardsList().then(response => {
        this.boardSetting.list = response;

        // eslint-disable-next-line prefer-destructuring
        if (response.length) {
          const defaultIndex = this.boardSetting.list.findIndex(item => item.is_default == "1");
          this.setUuidBoard(response[defaultIndex != -1 ? defaultIndex : 0].uuid);
          // eslint-disable-next-line prefer-destructuring
          if (this.$route.params.uuid_board) {
            this.setUuidBoard(this.$route.params.uuid_board || "");
          } else {
            this.$route.params.uuid_board = response[defaultIndex != -1 ? defaultIndex : 0].uuid;
            this.$router
              .push({ name: "crm", params: { uuid_board: this.$route.params.uuid_board || "" } })
              .catch(() => {});
          }
          this.boardsItem = this.boardSetting.list[defaultIndex != -1 ? defaultIndex : 0];

          this.getStateList().then(() => {
            if (this.uuid_tab.length) {
              this.uuid_filter = this.uuid_tab;
              this.tab.forEach((item, index) => {
                if (this.uuid_filter === item.uuid) {
                  this.activeTab = index;
                }
              });
            }
          });
          this.getSpeedLead();
          this.hasBoards = Boolean(response.length);
          this.getDataItemBoard();
        } else {
          this.boardSetting.visible = true;
          this.hasBoards = Boolean(response.length);
        }
        this.getTagStats();
      });
      this.setLoading(false);
    } catch (e) {
      console.log(e)
      this.setLoading(false);
    }
    if(this.choosenAssignedUser !== null && this.choosenAssignedUser !== true) {
      this.showUserSelector = true;
      this.getAssignedUsers();
    }
  },
  computed: {
    ...mapGetters("crm", {
      uuid_board: getterTypes.GET_UUID_BOARD,
      uuid_tab: getterTypes.GET_UUID_TAB
    }),
    ...mapGetters("userKey", {
      userKey: getterType.UserKey
    }),
    setSpeedLeadData() {
      switch (true) {
        case this.speedLeadValue == 0:
          return "panda";
          break;
        case this.speedLeadValue <= 5:
          return "snail";
          break;
        case this.speedLeadValue <= 10 && this.speedLeadValue > 5:
          return "koala";
          break;
        case this.speedLeadValue <= 20 && this.speedLeadValue > 10:
          return "tortoise";
          break;
        case this.speedLeadValue <= 40 && this.speedLeadValue > 20:
          return "rabbit";
          break;
        case this.speedLeadValue <= 70 && this.speedLeadValue > 40:
          return "horse";
        case this.speedLeadValue <= 100 && this.speedLeadValue > 70:
          return "shark";
          break;
        case this.speedLeadValue > 100:
          return "bat";
          break;
        default:
          return null;
          break;
      }
    },
    isAllowEditSuits() {
      return (
        (this.permissions.client &&
          this.permissions.access_crm &&
          this.boardsItem?.uuid_user_created !== this.getUser?.uuid) ||
        this.boardsItem?.uuid_user_created === this.getUser?.uuid
      );
    },
    isBoardCreator() {
      if (this.boardsItem) {
        return this.boardsItem?.uuid_user_created === this.getUser?.uuid;
      }
      return false;
    },
    isAllowEditState() {
      if ("access" in this.boardsItem) {
        return this.boardsItem.access.is_allow_state_edit === "1";
      } else {
        return true;
      }
    },
    isAllowStatisticReview() {
      if ("access" in this.boardsItem) {
        return this.boardsItem.access.is_allow_statistic_review === "1";
      } else {
        return true;
      }
    },
    isAllowAssignedUserChange() {
      if ("access" in this.boardsItem) {
        return this.boardsItem.access.is_allow_assigned_user_change === "1";
      } else {
        return true;
      }
    },
    isAccessDeportInfo() {
      if ("access" in this.boardsItem) {
        return this.boardsItem.access.is_access_deport_info === "1";
      } else {
        return true;
      }
    },
    isAllowSendOrder() {
      if ("access" in this.boardsItem) {
        return this.boardsItem.access.is_allow_send_order === "1";
      } else {
        return true;
      }
    }
  },
  methods: {
    ...mapActions("crm", {
      setUuidBoard: actionTypes.SET_UUID_BOARD,
      setUuidTab: actionTypes.SET_UUID_TAB
    }),
    ...mapActions("userKey", {
      setUserKey: actionType.SET_USER_KEY
    }),
    closeDeleteTabItemDialog() {
      this.deleteConfirm = {
        visible: false,
        data: {}
      };
    },
    deleteTabItem(item) {
      this.deleteConfirm = {
        visible: true,
        data: item
      };
    },
    async getScreenWidth() {
      const draggableElement = this.$refs.tabElement2;
      if (draggableElement) {
        this.colWidth = draggableElement.$el.clientWidth;
      }

      await new Promise(resolve => setTimeout(resolve, 100));

      const screenWidth = window.innerWidth;

      if (this.colWidth > ((screenWidth / 3) * 1.75)) {
        this.amountOfCols = 11;
      } else {
        this.amountOfCols = 'auto';
      }
    },
    formatUserName(user) {
      return `${user.user_details.name} ${user.user_details.surname}`;
    },
    closeSelector() {
      this.choosenAssignedUser = null;
      this.showUserSelector = false;
      this.setUserKey(this.choosenAssignedUser);
    },
    TotalMessageHandle(newTotalMessage) {
      this.totalMessage = newTotalMessage;
    },
    async getAssignedUsers() {
      try {
        if(this.choosenAssignedUser === null && this.permissions.can_choose_assigned_user) {
          this.choosenAssignedUser = this.getUserProperty('uuid');
        }
        this.showUserSelector = true;
        const params = {
          uuid_board: this.boardsItem.uuid
        };
        const res = await crmService.shareCrmBordUserList(params);
        this.assignedUsers = res;
      } catch (e) {
        console.log(e)
      }
    },
    async getSpeedLead() {
      try {
        if (!this.boardsItem.uuid || !this.permissions.can_see_leads) return;
        const res = await crmService.getLeadSpeed({ uuid_board: this.boardsItem.uuid });
        this.speedLeadValue = Number(res.message.split(":")[1]) || 0;
      } catch (e) {
        console.log(e)
      }
    },
    dblClick(item) {
      if (this.isAllowEditState) {
        item.uuid_user = this.openEditTab(item.noEdit);
        this.editedTab = { ...item };
      }
    },
    async getDataItemBoard() {
      try {
        await crmService.getCrmBoardsItemInfo(this.uuid_board).then(request => {
          // // orderDialog('request', request);

          this.boardsItem = {
            ...this.boardsItem,
            ...request
          };
        });
      } catch (e) {
        console.log(e)
      }
    },
    changeBoard() {
      // orderDialog('changeBoard');
      this.setUuidBoard(this.boardsItem.uuid || "");
      this.getStateList();
      this.getDataItemBoard();
      this.getTagsTemplateList();
      this.getTagStats();
      this.activeTab = 0;
      this.uuid_filter = null;
      this.$route.params.uuid_board = this.uuid_board;
      this.$router
        .push({ name: "crm", params: { uuid_board: this.uuid_board || "" } })
        .catch(() => {});
    },
    updateItemCallState(item) {
      if (item.is_call_state === "0") {
        item.is_call_state = "1";
      } else {
        item.is_call_state = "0";
      }
      // this.outSideEditTab()
    },
    async getStateList(newState = null) {
      try {
        if(!this.boardsItem.uuid)return;
        const stateList = await crmService.getCrmUserStateList(this.boardsItem.uuid);
        const startTab = {
          name: this.$t("btn.all"),
          uuid_user: true,
          uuid: "00000000-0000-0000-0000-000000000000",
          position: 0,
          noEdit: true,
          color: this.isDark ? "#1E1E1E" : "#FFF"
        };
        this.tab = [startTab, ...stateList];
        this.itemDialog.user_states = this.tab;
        if (newState) {
          if (this.uuid_tab.length) {
            this.uuid_filter = this.uuid_tab;
            this.tab.forEach((item, index) => {
              if (this.uuid_filter === item.uuid) {
                this.activeTab = index;
              }
            });
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getTagStats() {
      if (this.isBoardCreator) {
        try {
          const res = await crmService.getStatisticTags({
            uuid_board: this.boardsItem.uuid
          });
          this.tagsStats = res;
          this.getScreenWidth();
        } catch (e) {
          console.log(e)
        }
      }
    },
    async getTagsTemplateList() {
      try {
        if (!this.boardsItem.uuid) return;
        const res = await crmService.getTagsTemplateList({
          uuid_board: this.boardsItem.uuid
        });
        this.tags = res;
      } catch (e) {
        console.log(e)
      }
    },
    openEditTab(val) {
      if (val) {
        return true;
      }
      return false;
    },
    outSideEditTab() {
      // orderDialog('asdasd');
      Object.keys(this.tab).forEach(key => {
        if (!this.tab[key].uuid_user && !this.tab[key].noEdit) {
          if (this.tab[key].name.length) {
            // orderDialog(this.tab[key]);
            this.tab[key].uuid_user = true;
            this.editedTab.uuid_user = true;
            this.onUpdateTab(this.tab[key]);
          }
        }
      });
    },
    openItemDialog(type, item) {
      this.itemDialog.type = type;
      this.itemDialog.item = item;
      this.itemDialog.visible = true;
    },
    onKeyPressSelect(e) {
      e.preventDefault();
    },
    moveTab(val) {
      // orderDialog('testttt', val);
      this.activeTab = Number(val.moved.newIndex);
      // eslint-disable-next-line no-plusplus
      if (Number(val.moved.newIndex) < Number(val.moved.oldIndex)) {
        // orderDialog('right');
        for (let i = Number(val.moved.newIndex); i <= Number(val.moved.oldIndex); i++) {
          this.onUpdatePositionTab({
            ...this.tab[i],
            position: i
          });
        }
      } else {
        // orderDialog('left');
        // eslint-disable-next-line no-plusplus
        for (let i = Number(val.moved.oldIndex); i <= Number(val.moved.newIndex); i++) {
          this.onUpdatePositionTab({
            ...this.tab[i],
            position: i
          });
        }
      }

      this.updateItems(val.moved.element.uuid, val.moved.element.noEdit);
    },
    async onAddTab() {
      if (this.nameNewTab.length) {
        try {
          this.setLoading(true);
          this.loadingAddNewTab = true;
          const formData = new FormData();
          formData.append("name", this.nameNewTab);
          formData.append("uuid_board", this.boardsItem.uuid);
          formData.append("color", this.colorNewTab || "#FFF");

          this.colorNewTab = '#EEFDFF';

          const isDuplicate = this.tab.some(tabItem => tabItem.name === this.nameNewTab);
          if (isDuplicate) {
            this.setErrorNotification(this.$t("crm.status_already_exists"));
          }

          const newState = await crmService.addCrmUserState(formData);

          // this.tab.push(newState);

          if (!isDuplicate) {
            this.tab.push(newState);
          }

          this.nameNewTab = "";
          this.dialogNewTab = false;
          this.loadingAddNewTab = false;
          await this.getStateList().then(() => {
            this.uuid_filter = this.uuid_tab;
            this.tab.forEach((item, index) => {
              if (item.uuid === newState.uuid) {
                this.activeTab = index;
                this.updateItems(newState.uuid, newState.noEdit);
              }
            });
          });
          this.setLoading(false);
        } catch (e) {
          this.loadingAddNewTab = false;
          this.dialogNewTab = false;
          this.setLoading(false);
          console.log(e)
        }
      }
    },
    async onUpdatePositionTab(data) {
      try {
        const formData = new FormData();
        formData.append("uuid", data.uuid);
        formData.append("position", data.position);
        formData.append("name", data.name);

        await crmService.updateCrmUserState(formData);
      } catch (e) {
        console.log(e)
      }
    },
    async onUpdateTab(data) {
      if (!_.isEqual(this.editedTab, data)) {
        try {
          const formData = new FormData();
          formData.append("uuid", data.uuid);
          formData.append("name", data.name);
          // formData.append('uuid_board', this.boardsItem.uuid);
          formData.append("color", data.color);
          formData.append("position", data.position);
          formData.append("is_call_state", data.is_call_state);

          await crmService.updateCrmUserState(formData);
          // // orderDialog('newState', updateState);
          EventBus.$emit("crm-items-reset");
        } catch (e) {
          this.setErrorNotification(e.error.description);
        }
      }
    },
    updateItems(uuid, status) {
      // if('access' in this.boardsItem && this.boardsItem.access.is_allow_state_edit === '0')return
      if (this.dialogNewTab) {
        this.dialogNewTab = false;
      }
      this.chosenFilter = false;
      if (!status) {
        this.setUuidTab(uuid);
        this.uuid_filter = uuid;
      } else {
        this.uuid_filter = null;
        this.setUuidTab("");
      }
    }
  }
};
</script>

<style lang="scss">
.color-span {
  min-width: 14px;
  min-height: 14px;
  border: 1px solid #676767;
  border-radius: 50%;
  display: block;
  z-index: 100;
}
.select-shared-board__item {
  align-items: center;
  .v-icon {
    margin-right: auto;
    width: 15px;
    height: 15px;
  }
}
.btn-settingBoard {
  min-width: 42px !important;
  padding: 0 !important;
}
.crm-tabDraggable {
  display: flex;
}
.section-crm {
  &__content {
  }
  .crm-tag {
    margin-bottom: 0px;
    padding: 0 9px;
    font-size: 13px !important;
    color: grey !important;

    .v-tabs-bar {
      height: auto;
    }
    .v-tab {
      pointer-events: none;
      text-transform: none;
      margin: 0 1.5px;
      min-width: auto;
      font-size: 10px !important;
      border: 2px solid transparent;

      &:before {
        display: none !important;
      }

      &.active {
        color: grey !important;
        .tab-text {
        }
      }
    }
  }
  .crm-tab {
    margin-bottom: 0px;
    border-bottom: 2px solid #eeeeee;
    padding: 0 0 0 9px;
    &__add {
      border: 1px solid #eeeded;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      cursor: pointer;
      margin: 2px 5px 0;
    }
    &__addInput {
      margin: 2px 5px 0;
      // background: #ffffff;
      display: flex;
      align-items: center;
      border: 1px solid #eeeded;
      box-shadow: 1px 2px 10px rgba(154, 154, 154, 0.15);
      border-radius: 4px;
      height: 30px;
      input {
        outline: 0;
        padding: 0 10px;
        width: 90px;
      }
      &-color {
        display: flex;
        align-items: center;
        span {
          width: 14px;
          height: 14px;
          border: 1px solid #676767;
          border-radius: 50%;
          display: block;
        }
      }
    }
    .v-tabs-bar {
      height: auto;
    }
    .v-tab {
      padding: 0 0 2px !important;
      margin: 0 1.5px;
      min-width: auto;

      border: 2px solid transparent;
      border-radius: 6px 6px 0 0;

      &:before {
        display: none !important;
      }

      .tab-edit {
        margin: 0 5px;
        // background: #ffffff;
        display: flex;
        align-items: center;
        box-shadow: 1px 2px 10px rgba(154, 154, 154, 0.15);
        border-radius: 4px;
        height: 30px;
        input {
          outline: 0;
          padding: 0 10px;
          width: 90px;
        }
        &-color {
          display: flex;
          align-items: center;
          span {
            width: 14px;
            height: 14px;
            border: 1px solid #676767;
            border-radius: 50%;
            display: block;
          }
        }
      }
      &.black {
        border: 2px solid #272727 !important;
        border-bottom-color: transparent;
        .tab-text {
          padding: 5px 17px 7px !important;
          color: #fafafa;
        }
      }
      .tab-text {
        border: 1px solid #eeeeee;
        border-radius: 6px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #000;
        text-transform: none;
        padding: 5px 15px;
        display: flex;
        align-items: center;
        span {
          padding-left: 10px;
          font-weight: bold;
          font-size: 12px;
          line-height: 14px;
        }
      }
      &.active {
        padding: 0 !important;
        border: 0;
        &.white {
          border: 2px solid #eeeeee !important;
          border-bottom-color: transparent;
          .tab-text {
            padding: 5px 17px 7px !important;
          }
        }
        &.black {
          border: 2px solid #272727 !important;
          border-bottom-color: transparent;
          .tab-text {
            padding: 5px 17px 7px !important;
            color: #fafafa;
          }
        }
        .tab-text {
          padding: 7px 19px 9px !important;
          border-radius: 6px 6px 0 0;
          border: 1px solid transparent;
          color: #272727;
        }
      }
    }
  }
}
</style>
