<template>
  <tr>
    <td style="opacity: 0.3;">{{ item.index }}</td>
    <td class="icon text-center">
      <ns-badge :show-badge="item.is_reviewed === '0'" ns-color="success" ns-content="!">
        <VBtn
          class="btn-edit"
          color="primary"
          dark
          depressed
          small
          @click="editItem"
          :class="{ sm: !isLarge }"
        >
          <VIcon>mdi-file-document-edit-outline</VIcon>
        </VBtn>
      </ns-badge>
    </td>
    <td :class="isOLX || isInstagram || isFacebook || isRozetka ? 'md' : ''" class="sm">
      <VRow align="center">
        <VCol cols="auto" class="px-0 pl-1 copyTxt" @click="copyToClipboard(item.number)">
          {{ item.number || "--" }}
        </VCol>
        <v-menu open-on-hover top offset-y v-if="item.tags.split('').length">
          <template v-slot:activator="{ on, attrs }">
            <VBtn class="mx-1" style="width: 16px" v-bind="attrs" v-on="on" icon small>
              <VIcon small color="deep-purple">mdi-tag</VIcon>
            </VBtn>
          </template>
          <VCard min-height="50" class="pa-2">
            <v-chip-group column>
              <v-chip
                v-for="(tag, index) in item.tags.split(';')"
                :key="index"
                small
                color="deep-purple"
                class="text-white"
              >
                {{ tag }}
              </v-chip>
            </v-chip-group>
          </VCard>
        </v-menu>
        <v-tooltip color="deep-purple" top v-if="isInstagram">
          <template v-slot:activator="{ on, attrs }">
            <VIcon class="mr-1" v-on="on" v-bind="attrs" small color="pink">mdi-instagram</VIcon>
          </template>
          <span>Instagram</span>
        </v-tooltip>
        <v-tooltip color="deep-purple" top v-if="isFacebook">
          <template v-slot:activator="{ on, attrs }">
            <VIcon class="mr-1" v-on="on" v-bind="attrs" v-if="isFacebook" small color="blue"
              >mdi-facebook</VIcon
            >
          </template>
          <span>Facebook</span>
        </v-tooltip>
        <v-tooltip color="deep-purple" top v-if="isRozetka">
          <template v-slot:activator="{ on, attrs }">
            <v-img
              class="mr-1"
              v-on="on"
              v-bind="attrs"
              style="max-width: 14px; height: 14px;"
              src="@/assets/images/rozetka_logo.png"
            />
          </template>
          <span>Rozetka</span>
        </v-tooltip>
        <v-tooltip color="deep-purple" top v-if="isOLX">
          <template v-slot:activator="{ on, attrs }">
            <v-img
              class="mr-1"
              v-on="on"
              v-bind="attrs"
              style="max-width: 14px; height: 14px; border-radius: 10px;"
              src="@/assets/images/olx_logo.png"
            />
          </template>
          <span>OLX</span>
        </v-tooltip>
      </VRow>
    </td>
    <td>
      {{ item.ttn || "--" }}
    </td>
    <td>
      {{ item.delivery_address_details.phone || "--" }}
    </td>
    <td>
      {{ item.delivery_address_details.name || "--" }}
    </td>
    <td>
      {{ item.delivery_address_details.surname || "--" }}
    </td>

    <td>
      {{ item.items_total_price || "0" }}
    </td>
    <td
      @mouseenter="changeUserState = false"
      @mouseleave="changeUserState = true"
      v-click-outside="closeChangeUserState"
    >
      <VSelect
        :style="
          `background:${bgItem}; color: ${
            $vuetify?.theme?.dark && colors && bgItem === colors[0]?.color ? '#fafafa' : '#000'
          }; margin: 3px 5px 3px 5px`
        "
        :items="colors"
        item-text="name"
        item-value="uuid"
        placeholder="Оберіть "
        v-model="item.uuid_user_state"
        :hide-details="true"
        @change="setUserState"
        :disabled="changeUserState"
        dense
        outlined
        class="select-userState"
        :menu-props="{ bottom: true, offsetY: true }"
      >
        <template slot="item" slot-scope="{ item }">
          <div class="select-userState__item">
            <span :style="`background-color: ${item.color};`"></span>
            {{ item.name }}
          </div>
        </template>
        <template slot="selection" slot-scope="{ item }">
          <div
            class="select-userState__item font-weight-medium"
            :style="{
              color:
                $vuetify.theme.dark && item.color != '#7B00FF'
                  ? '#444'
                  : $vuetify.theme.dark && item.color === '#7B00FF'
                  ? '#333'
                  : 'black'
            }"
          >
            {{ item.name }}
          </div>
        </template>
      </VSelect>
    </td>

    <td>
      {{ item.time_created | createdAt(item.time_created, currentLanguage) }}
    </td>
    <td class="text-center icon">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <VIcon
            v-bind="attrs"
            v-on="on"
            dense
            class="mr-1"
            :color="item.is_call_required === '1' ? 'primary' : 'grey'"
          >
            {{ item.is_call_required === "1" ? "mdi-phone" : "mdi-phone-off" }}
          </VIcon>
        </template>
        <span>{{
          item.is_call_required === "1" ? "Потребує колл центру" : "Не потребує колл центру"
        }}</span>
      </v-tooltip>
    </td>
    <td class="text-center icon">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <VIcon
            v-bind="attrs"
            v-on="on"
            dense
            class="mr-1"
            :color="item.type === 'out' ? 'green' : 'yellow darken-1'"
          >
            {{ item.type === "out" ? "mdi-truck-delivery" : "mdi-alarm" }}
          </VIcon>
        </template>
        <span>{{ item.type === "out" ? "Відправлено" : "Очікує &#9785;" }}</span>
      </v-tooltip>
    </td>
    <td class="text-center icon">
      <v-menu
        bottom
        origin="center center"
        transition="slide-x-transition"
        :close-on-content-click="false"
        :offset-x="true"
        z-index="202"
        v-model="cashierDialogMenu"
        v-if="item.uuid_receipt != '00000000-0000-0000-0000-000000000000'"
      >
        <template v-slot:activator="{ on, attrs }">
          <VBtn
            icon
            v-on="on"
            v-bind="attrs"
            :disabled="item.uuid_receipt === '00000000-0000-0000-0000-000000000000'"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <VIcon v-on="on" v-bind="attrs" class="pointer-events-auto" color="orange">
                  mdi-receipt-text
                </VIcon>
              </template>
              <span>{{ $t("orderDialog.checkbox_integration") }}</span>
            </v-tooltip>
          </VBtn>
        </template>
        <cashierDialog :item="item" @close="cashierDialogMenu = false" v-if="cashierDialogMenu" />
      </v-menu>
    </td>
    <td>
      <div
        v-if="
          'state_details' in item &&
            (item?.state_details[`${currentLanguage.key}_name`] || item?.state_details.name)
        "
      >
        <div class="d-flex align-center justify-content-start ">
          <StatusCircle :status-name="item.id_state" :type="'supply'" :class="{ sm: !isLarge }" />
          <div class="elispsis">
            {{
              item?.state_details[`${currentLanguage.key}_name`] || item?.state_details.name || ""
            }}
          </div>
        </div>
      </div>
      <div v-else>
        <span>--</span>
      </div>
    </td>
  </tr>
</template>

<script>
import ClickOutside from "vue-click-outside";
import { mapGetters } from "vuex";
import * as getterTypes from "@/store/modules/crm/types/getters";
import EventBus from "../../../events/EventBus";
import crmService from "../../../services/request/crm/crmService";
import notifications from "../../../mixins/notifications";
import SizeUi from "../../../mixins/SizeUi";
import StatusCircle from "@/components/move-list/StatusCircle.vue";
import cashierDialog from "@/components/orderDialog/components/dialogs/cashierDialog.vue";

export default {
  name: "CrmItemRow",
  mixins: [notifications, SizeUi],
  directives: {
    ClickOutside
  },
  props: {
    uuid_board: String,
    item: {
      required: true
    },
    colors: {
      required: true
    }
  },
  data: () => ({
    dateMenu: false,
    changeUserState: true,
    cashierDialogMenu: false
  }),
  components: {
    StatusCircle,
    cashierDialog
  },
  mounted() {},
  methods: {
    closeChangeUserState() {
      this.changeUserState = true;
    },
    async setUserState() {
      try {
        // orderDialog('setUserState');
        const formData = new FormData();
        formData.append("uuid_order", this.item.uuid);
        formData.append("uuid_state", this.item.uuid_user_state);
        formData.append("uuid_board", this.uuid_board);

        await crmService.setUserState(formData);
        this.changeUserState = false;
        EventBus.$emit("crm-items-reset");
      } catch (e) {
        console.log(e);
      }
    },
    userState(uuid) {
      const info = this.colors.filter(item => item.uuid === uuid);
      return info[0].name || "--";
    },
    editItem() {
      this.$emit("toggleRow", this.item.uuid);
      EventBus.$emit("crm-item-edit", this.item);
    },
    async copyToClipboard(e) {
      try {
        await navigator.clipboard.writeText(e);
        this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
      } catch (e) {
        console.log(e);
      }
    }
  },
  computed: {
    ...mapGetters(["currentLanguage"]),
    ...mapGetters("crm", {
      uuid_tab: getterTypes.GET_UUID_TAB
    }),
    isInstagram() {
      const lowercaseTags = this.item.tags.split(";").map(element => {
        return element.toLowerCase();
      });
      return (
        lowercaseTags.length &&
        (lowercaseTags.indexOf("insta") != -1 || lowercaseTags.indexOf("instagram") != -1)
      );
    },
    isFacebook() {
      const lowercaseTags = this.item.tags.split(";").map(element => {
        return element.toLowerCase();
      });
      return (
        lowercaseTags.length &&
        (lowercaseTags.indexOf("fb") != -1 || lowercaseTags.indexOf("facebook") != -1)
      );
    },
    isRozetka() {
      const lowercaseTags = this.item.tags.split(";").map(element => {
        return element.toLowerCase();
      });
      return lowercaseTags.length && lowercaseTags.indexOf("rozetka") != -1;
    },
    isOLX() {
      const lowercaseTags = this.item.tags.split(";").map(element => {
        return element.toLowerCase();
      });
      return lowercaseTags.length && lowercaseTags.indexOf("olx") != -1;
    },
    bgItem() {
      // // orderDialog('this.uuid_tab', this.uuid_tab);
      if (this.colors.length) {
        const colors = this.colors.filter(
          colorItem => colorItem.uuid === this.item.uuid_user_state
        );
        if (colors.length) {
          return colors[0].color;
        }
      }
      return "";
    }
  }
};
</script>

<style lang="scss">
.select-userState__item {
  display: flex;
  align-items: center;
  span {
    margin-right: 10px;
    width: 15px;
    height: 15px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #eeeeee;
  }
}
.select-userState {
  &.v-input--is-disabled {
    fieldset {
      color: transparent !important;
    }
    .v-input__append-inner {
      opacity: 0;
    }
  }
  .v-select__selections {
    color: #676767 !important;
  }
  &.v-text-field.v-text-field--outlined .v-input__control .v-input__append-inner {
    margin-top: 4px !important;
  }
  .v-input__slot {
    min-height: auto !important;
    height: 32px;
  }
}

.crm-order__data-item {
  border-bottom: 1px solid #f2f2f2;
  .td {
    height: 40px;
    padding: 4px 16px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 32px;
    color: #676767;
    border-left: 1px solid #f2f2f2;
    overflow: hidden;
    text-overflow: ellipsis;
    &:last-child {
      padding: 4px;
      border-right: 1px solid #f2f2f2;
      text-overflow: unset;
    }
  }
  .btn-edit {
    min-width: auto !important;
    padding: 0 4px !important;
  }
}
</style>
