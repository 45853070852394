import requestService from "../requestService";

const prefix = "/gallery";

export default {
  async getList(params = {}) {
    const response = await requestService.get(`${prefix}/list`, params);
    return response?.data?.object;
  },
  async addImage(params) {
    const response = await requestService.post(`${prefix}/add`, params);
    return response?.data?.object;
  },
  async deleteImage(params) {
    const response = await requestService.delete(`${prefix}/delete/${params}`);
    return response?.data?.object;
  }
};
