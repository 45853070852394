<template>
  <VCol cols="12">
    <v-card-subtitle style="padding: 0px 10px;">{{ $t("crm.cashiers") }}</v-card-subtitle>
    <div class="listCounterparty">
      <v-radio-group v-model="cashierDefault" hide-details="auto">
        <div
          class="listCounterparty__item"
          :style="$vuetify.breakpoint.xs ? 'flex-wrap: wrap; justify-content: center' : ''"
          v-for="(item, index) in cashiers"
          :key="index"
        >
          <span
            :style="
              $vuetify.breakpoint.xs
                ? 'text-align: center; width: 100%; max-width: 100%; display: block;'
                : ''
            "
          >
            {{ item.name }}
          </span>
          <div class="listCounterparty__item-buttons">
            <v-radio
              :key="index"
              :value="index"
              class="mb-0"
              @change="changeRadioCashier(item.uuid)"
              color="success"
            ></v-radio>
            <VBtn icon small @click="editCashier(item)"><VIcon>mdi-pencil</VIcon></VBtn>
            <VBtn
              icon
              small
              @click="(deleteDialog = true), (selectedItem = item)"
              :disabled="item.is_default == '1'"
              :style="item.is_default == '1' ? 'cursor: default' : ''"
              ><VIcon color="error">mdi-trash-can-outline</VIcon></VBtn
            >
          </div>
        </div>
        <div class="listCounterparty__item pt-2"></div>
      </v-radio-group>
      <VBtn
        @click="
          CashierDialog.visible = true;
          CashierDialog.type = 'create';
        "
        class="success-bg text-white br-10 w-100"
      >
        {{ $t("crm.add_cashier") }}
      </VBtn>
    </div>
    <boardAddCashierDialog
      :visible="CashierDialog.visible"
      @close="CashierDialog.visible = false"
      v-if="CashierDialog.visible"
      :boardItem="boardItem"
      :type="CashierDialog.type"
      @cashier_added="cashiersList()"
      :item="CashierDialog.data"
    />
    <ConfirmDialog
      :visible="deleteDialog"
      @close="deleteDialog = false"
      @onConfirm="
        deleteCashier(selectedItem);
        deleteDialog = false;
      "
      @onCancel="deleteDialog = false"
      :typeAlert="'error'"
    >
      <template v-slot:header>
        {{ $t("crm.delete_cashier.title") }}
      </template>
      <template v-slot:description>
        {{ $t("crm.delete_cashier.desc") }}
      </template>
    </ConfirmDialog>
  </VCol>
</template>

<script>
import crmFormsService from "@/services/request/crm/crmForms";
import boardAddCashierDialog from "./boardAddCashierDialog.vue";
import notifications from "@/mixins/notifications";
import ConfirmDialog from "@/components/dialog/ConfirmDialog.vue";
import EventBus from "@/events/EventBus";

export default {
  data: () => ({
    CashierDialog: {
      visible: false,
      type: "create",
      data: {}
    },
    deleteDialog: false,
    cashiers: [],
    cashierDefault: 0,
    selectedItem: null
  }),
  mixins: [notifications],
  props: {
    boardItem: {
      require: true
    }
  },
  components: {
    boardAddCashierDialog,
    ConfirmDialog
  },
  async mounted() {
    await this.cashiersList();
    EventBus.$on("crm-cashier-added", async boardItem => {
      this.boardItem = boardItem;
      await this.cashiersList();
    });
  },
  methods: {
    async cashiersList() {
      this.loading = true;
      try {
        const params = {};
        params.uuid_board = this.boardItem.uuid;
        const response = await crmFormsService.getCashiers(params);
        this.cashiers = response;
        this.cashierDefault = this.cashiers.findIndex(cashier => cashier.is_default == 1);
        if (this.cashiers.length == 1) {
          this.changeRadioCashier(this.cashiers[0].uuid);
          this.cashierDefault = 0;
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    editCashier(item) {
      this.CashierDialog = {
        visible: true,
        type: "edit",
        data: item
      };
    },
    async changeRadioCashier(uuid) {
      this.loading = true;
      try {
        await crmFormsService.setDefaultCashier(uuid);
        this.cashiers.forEach(cashier => {
          cashier.is_default = 0;
        });
        const newDefault = this.cashiers.find(cashier => {
          return cashier.uuid === uuid;
        });
        newDefault.is_default = 1;
        if (this.cashiers.length != 1) {
          this.setSuccessNotification(this.$t("crm.set_default_cashier"));
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async deleteCashier(item) {
      await crmFormsService.deleteCashier(item.uuid);
      this.cashiersList();
    }
  }
};
</script>
<style lang="scss">
.listCounterparty {
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.38);

  &__item {
    padding: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.38);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-buttons {
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    &:last-child {
      border: 0;
    }
  }
}
</style>
