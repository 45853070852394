<template>
  <v-dialog v-model="visibility" max-width="500">
    <v-card>
      <v-card-title
        class="headline"
        :class="{ 'px-0': $vuetify.breakpoint.smAndDown }"
      >
        <v-alert text dense :type="'error'" class="w-100">
          {{ $t('confirmDialog.create_user') }}
        </v-alert>
      </v-card-title>
      <v-card-text>
        <div class="font-weight-bold mb-5">
          {{ $t('confirmDialog.user') }} {{ email }} {{ $t('confirmDialog.does_not_exist') }}. {{ $t('confirmDialog.need_create_user') }}?
        </div>
        <v-row>
          <v-col class="pb-0 mb-0" cols="12">
            <v-text-field
              outlined
              dense
              class="br-10"
              :label="$t('form.name')"
              v-model="user.name"
              v-on:keypress="validationForName($event)"
              @paste="validationForName($event)"
              :error-messages="nameError"
            >
            </v-text-field>
          </v-col>
          <v-col class="pb-0 mb-0" cols="12">
            <v-text-field
              outlined
              dense
              class="br-10"
              :label="$t('form.surname')"
              v-model="user.surname"
              v-on:keypress="validationForSurname($event)"
              @paste="validationForSurname($event)"
              :error-messages="surnameError"
            >
            </v-text-field>
          </v-col>
          <!-- <v-col class="py-0 my-0" cols="12">
            <v-text-field
              outlined
              dense
              class="br-10"
              :label="$t('form.phone_number')"
              v-model="user.phone"
              v-on:keypress="onlyNumber($event)"
              :maxLength="20"
              prefix="+38"
              @paste="noEngTextPasted($event, true)"
              :error-messages="phoneError"
            >
            </v-text-field>
          </v-col> -->
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row class="my-0 py-0">
          <v-col cols="6">
            <v-btn
              color="error"
              block
              text
              class="w-100 br-10"
              @click="onCancel"
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
              {{ $t("btn.cancel") }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              :color="'error'"
              block
              class="w-100 br-10"
              @click="onConfirm"
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
              {{ $t("btn.confirm") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import authService from "@/services/request/auth/authService";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import notifications from "@/mixins/notifications";
import _ from "lodash";
import lang from "@/mixins/language";
import { mapGetters } from "vuex";
import {
  validationForName, validationForSurname,
} from "@/mixins/helpers";

export default {
  mixins: [validationMixin, notifications, lang],
  data: () => ({
    user: {},
  }),
  props: {
    email: {
      require: true,
    },
    visible: {
      require: true,
    },
  },
  validations: {
    user: {
      name: {
        required,
      },
      surname: {
        required
      }
    },
  },
  watch: {},
  mounted() {},
  methods: {
    validationForSurname,
    validationForName,
    async onConfirm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          const formData = new FormData();
          formData.append("name", this.user.name);
          formData.append("surname", this.user.surname);
          formData.append("email", this.email);
          formData.append("lang", this.currentLanguage.lang);
          formData.append(
            "url_landing",
            `https://${window.location.host}/activation/`
          );

          await authService.createUserFromCRM(formData).then(() => {
             this.setSuccessNotification("Користувача створено!");
             this.$emit("onConfirm")
          });
        } catch (e) {
          console.log(e)
        }
      }
    },
    onCancel() {
      this.$emit("onCancel");
    },
  },
  computed: {
    ...mapGetters(["currentLanguage"]),
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
      },
    },
    nameError() {
      const errors = [];
      if (!this.$v.user.name.$dirty) {
        return errors;
      }
      !this.$v.user.name.required &&
        errors.push(this.$t("form.errors.NameRequired"));
      return errors;
    },
    surnameError() {
      const errors = [];
      if (!this.$v.user.surname.$dirty) {
        return errors;
      }
      !this.$v.user.surname.required &&
        errors.push(this.$t("form.errors.SurnameRequired"));
      return errors;
    },
    // phoneError() {
    //   const errors = [];
    //   if (!this.$v.user.phone.$dirty) {
    //     return errors;
    //   }
    //   !this.$v.user.phone.required &&
    //     errors.push(this.$t("form.errors.PhoneNumberRequired"));
    //   !this.$v.user.phone.phoneMask &&
    //     errors.push(this.$t("form.errors.PhoneNumberMustBeLike"));
    //   return errors;
    // },
  },
};
</script>
