<template>
  <VDialog content-class="cross__dialog" v-model="visibity" max-width="600"  :transition="$dialogTransition">
    <VCard>
      <div class="cross__inner">
        <v-btn fab small  elevation="0" @click="showConfirmDialog">
          <VIcon>mdi-close</VIcon>
        </v-btn>
      </div>
      <VCardTitle>
        {{$t('crm.user_settings')}}
      </VCardTitle>
      <v-card-text>
        <v-row>
          <v-col cols="8">
            <v-row>
              <v-col cols="12">
                <VTextField
                  v-model="itemCopy.utm_source"
                  outlined
                  dense
                  color="green"
                  label="utm_source"
                  hide-details="auto"
                />
              </v-col>
              <v-col cols="6">
                <VTextField
                  v-model="itemCopy.utm_medium"
                  outlined
                  dense
                  color="green"
                  label="utm_medium"
                  hide-details="auto"
                />
              </v-col>
              <v-col cols="6">
                <VTextField
                  v-model="itemCopy.utm_term"
                  outlined
                  dense
                  color="green"
                  label="utm_term"
                  hide-details="auto"
                />
              </v-col>
              <v-col cols="6">
                <VTextField
                  v-model="itemCopy.utm_content"
                  outlined
                  dense
                  color="green"
                  label="utm_content"
                  hide-details="auto"
                />
              </v-col>
              <v-col cols="6">
                <VTextField
                  v-model="itemCopy.utm_campaign"
                  outlined
                  dense
                  color="green"
                  label="utm_campaign"
                  hide-details="auto"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="4">
            <v-checkbox :label="$t('crm.access_deport')" v-model="itemCopy.accessDeport" color="success"></v-checkbox>
            <v-checkbox :label="$t('crm.change_status')" v-model="itemCopy.changeStatus" color="success"></v-checkbox>
            <v-checkbox :label="$t('crm.allow_send')" v-model="itemCopy.allowSendOrder" color="success"></v-checkbox>
            <v-checkbox :label="$t('crm.allowAssigned')" v-model="itemCopy.allowAssignedUserChange" color="success"></v-checkbox>
            <v-checkbox :label="$t('crm.allowStatistic')" v-model="itemCopy.allowStatisticReview" color="success"></v-checkbox>
            <v-checkbox :label="$t('crm.allowDeliveries')" v-model="itemCopy.allowSeeAllDeliveries" color="success"></v-checkbox>
            <v-checkbox :label="$t('crm.allowDeliveriesWithUtm')" v-model="itemCopy.allowSeeDeliveriesUtmMarks" color="success"></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn plain @click="$emit('close')">
          {{ $t("btn.cancel") }}
        </v-btn>
        <v-spacer />

        <v-btn
          class="success-bg"
          color="white--text"
          @click="onUpdate"
          :disabled="loadingSharedUpdate || !isChanged"
          :loading="loadingSharedUpdate"
        >
          {{ $t("btn.update") }}
        </v-btn>
      </v-card-actions>
    </VCard>
    <ConfirmLeftDialog
      :visible="visibleConfirm"
      @close="visibleConfirm = false"
      @delete="onClose"
    />
  </VDialog>
</template>

<script>
import _ from "lodash";
import EventBus from "@/events/EventBus";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";

export default {
  components: {
    ConfirmLeftDialog
  },
  props: {
    visible: {
      require: true
    },
    item: {
      require: true
    },
    loadingSharedUpdate: {
      require: true
    }
  },
  data: () => ({
    itemCopy: {},
    visibleConfirm: false,
    isChanged: false
  }),
  mounted() {

    this.itemCopy = { ...this.item };


  },
  methods: {
    onClose() {
      this.$emit("close");
      this.resetRequestData();
      this.$v.$reset();
    },
    showConfirmDialog() {
      if (this.isChanged) {
        this.visibleConfirm = true;
      } else {
        this.onClose();
      }
    },
    resetData() {
      this.itemCopy = {};
      this.accessDeport = false;
      this.changeStatus = false;
      this.allowSendOrder = false;
      this.allowAssignedUserChange = false;
      this.allowStatisticReview = false;
      this.allowSeeAllDeliveries = false;
      this.allowSeeDeliveriesUtmMarks = false;
    },
    onUpdate() {
      const accessParams = {
        is_access_deport_info: this.itemCopy.accessDeport,
        is_allow_state_edit: this.itemCopy.changeStatus,
        is_allow_send_order: this.itemCopy.allowSendOrder,
        is_allow_assigned_user_change: this.itemCopy.allowAssignedUserChange,
        is_allow_statistic_review: this.itemCopy.allowStatisticReview,
        is_allow_all_orders_review: this.itemCopy.allowSeeAllDeliveries,
        is_allow_utm_match_orders_review: this.itemCopy.allowSeeDeliveriesUtmMarks,
      };
      const result = { ...this.itemCopy, accessParams };
      this.$emit("update_user", result);
    }
  },
  watch: {
    itemCopy: {
      deep: true,
      handler() {
        this.isChanged = !_.isEqual(this.itemCopy, this.item);
      }
    }
  },
  computed: {
    visibity: {
      get() {
        return this.visible;
      },
      set() {
        this.resetData();
        return this.$emit("close");
      }
    }
  }
};
</script>

<style></style>
