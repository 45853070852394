<template>
  <div>
    <v-row class="mt-1">
      <v-col cols="6">
        <v-row>
          <v-col cols="12">
            <VTextField
              v-model="dataForm.name"
              outlined
              dense
              color="green"
              class="br-10"
              :label="$t('form.title')"
              hide-details="auto"
              :error-messages="errors.nameEditErrors"
              @keypress="validationForTitle($event)"
              @paste="validationForTitle($event)"
            />
          </v-col>
          <v-col cols="12">
            <VTextarea
              height="104"
              no-resize
              rows="3"
              color="green"
              class="br-10"
              :placeholder="$t('form.comment')"
              v-model="dataForm.description"
              hide-details="auto"
              :error-messages="errors.descriptionEditErrors"
              outlined
              dense
            ></VTextarea>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col cols="12">
            <VTextField
              v-model="dataForm.utm_source"
              outlined
              dense
              color="green"
              label="utm_source"
              hide-details="auto"
            />
          </v-col>
          <v-col cols="6">
            <VTextField
              v-model="dataForm.utm_medium"
              outlined
              dense
              color="green"
              label="utm_medium"
              hide-details="auto"
            />
          </v-col>
          <v-col cols="6">
            <VTextField
              v-model="dataForm.utm_term"
              outlined
              dense
              color="green"
              label="utm_term"
              hide-details="auto"

            />
          </v-col>
          <v-col cols="6">
            <VTextField
              v-model="dataForm.utm_content"
              outlined
              dense
              color="green"
              label="utm_content"
              hide-details="auto"
            />
          </v-col>
          <v-col cols="6">
            <VTextField
              v-model="dataForm.utm_campaign"
              outlined
              dense
              color="green"
              label="utm_campaign"
              hide-details="auto"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="ma-0">
        <v-btn @click="categoriesDialog = true" color="primary" text>
          <v-icon class="mr-1">mdi-select-group</v-icon>{{$t('crm.all_categories')}}
        </v-btn>
      </v-col>
    </v-row>

    <all-categories :visible="categoriesDialog" :formUuid="dataForm.uuid" v-if="categoriesDialog" @close_modal="categoriesDialog = false" />
    <!-- <v-row class="pb-3">
          <v-col cols="6">
            <VBtn class="w-100 br-10 text-transform-none" dark @click="backModal()">
              {{ $t("btn.back") }}
            </VBtn>
          </v-col>
          <v-col cols="6">
            <VBtn
              :disabled="!isEqualFormData"
              class="success-bg text-white text-transform-none br-10 w-100"
              @click="updateFormsItem"
            >
              {{ $t("btn.save") }}
            </VBtn>
          </v-col>
        </v-row> -->
  </div>
</template>

<script>
import _ from "lodash";
import { validationMixin } from "vuelidate";
import notifications from "@/mixins/notifications";
import language from "@/mixins/language";
import crmForms from "@/services/request/crm/crmForms";
import ConfirmDialogSlots from "../../../../dialog/ConfirmDialog.vue";
import CrmFormProduct from "./productTabs/crmFormProduct.vue";
import CrmFormListProduct from "./productTabs/crmFormListProduct.vue";
import EventBus from "@/events/EventBus";
import allCategories from '../categoriesForms/allCategories.vue'
import {validationForTitle} from "@/mixins/helpers";

const defaultUtmData = {
  utm_term: null,
  utm_source: null,
  utm_medium: null,
  utm_content: null,
  utm_campaign: null
};
export default {
  data: () => ({
    confirmDialog: false,
    visibleConfirm: false,
    deleteUUID: null,
    typeProductForm: "add",
    tabStep: 0,
    dataProduct: {},
    isEqualFormData: false,
    categoriesDialog: false,
  }),

  props: {
    errors: {
      required: true
    },
    dataForm: {
      required: true,
      type: Object
    },

    resetTabs: {
      required: true
    }
  },

  methods: {
    validationForTitle,
    onResetTab() {

    },
    backFormProduct() {
      this.getProductList();
      this.tabStep = 0;
    },
    showConfirmDialog() {
      if (this.changed) {
        this.visibleConfirm = true;
      } else {
        this.onClose();
      }
    },
    onClose() {
      this.$v.$reset();
      this.visibility = false;
    },
    backModal() {
      this.$emit("back");
    },
    delayItemProducts(uuid) {
      this.deleteUUID = uuid;
      this.confirmDialog = true;
    },
    async confirmDeleteProduct() {
      const uuid = this.deleteUUID;
      const respons = await crmForms.deleteProductItem(uuid);

      if (respons.status === "success") {
        this.listProducts = this.listProducts.filter(item => item.uuid !== uuid);
      }
    },
    openForm(uuid) {
      const route = this.$router.resolve({
        name: "market",
        params: {
          id: uuid
        }
      });
      window.open(route.href, "_blank");
    }
  },
  mounted() {
    EventBus.$on("crm-form-setProduct", product => {
      this.tabStep = 1;
    });
    const cookieName = `utm_data-${this.dataForm.uuid}`;
    const cookieUtmData = this.$cookies.get(cookieName);
    this.utmData = cookieUtmData || defaultUtmData;
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
      }
    }
  },
  mixins: [notifications, validationMixin, language],
  watch: {
    resetTabs: {
      deep: true,
      handler(e) {

        if (e) {
          this.tabStep = 0;
        }
      }
    },
    dataForm: {
      deep: true,
      handler(e) {}
    },
  },
  components: {
    ConfirmDialogSlots,
    CrmFormProduct,
    CrmFormListProduct,
    allCategories,
  }
};
</script>

<style lang="scss">
@import "../../../../orderDialog/orderDialog";
.productPlaceholder {
  display: flex;
  // padding-top: 52px;
  &__image {
    width: 150px;
    height: 150px;
    border: 1px dashed grey;
    border-radius: 10px;
    margin-right: 25px;
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__add {
    width: 100%;
    height: 100%;
    background-color: #f1f1f1;
    cursor: pointer;
    &:hover span {
      background-color: green;
    }
    span {
      border-radius: 2px;
      transition: all 0.3s ease;
      width: 50px;
      height: 5px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: grey;
      &:last-child {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }
  }
  &__info {
    padding-top: 15px;
  }
  &__name {
    font-size: 18px;
    margin-bottom: 15px;
  }
  &__option {
    font-size: 16px;
  }
}
</style>
