<template>
  <div class="mt-1">
    <v-row>
      <v-col cols="6">
        <!-- <div class="title d-flex mb-5 justify-content-space-between align-center">
          <div class="d-flex">
            <v-icon class="mr-2">mdi-package-variant-closed</v-icon>
            <div v-if="!$vuetify.breakpoint.smAndDown">
              {{ $t("crm.itemForm") }}: {{ dataProduct.name }}
            </div>
          </div>
        </div> -->
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col
                cols="5"
                class="text-center justify-center d-flex"
                style="flex-direction:column"
              >
                <label for="product_photo_prev" class="text-black">
                  <div class="text-center d-flex justify-center">
                    <div
                      style="width: 100%; height: 150px"
                      class="
                        d-flex
                        br-10
                        justify-center
                        align-center
                        photo-area
                        position-relative
                      "
                      :class="{
                        'background-img': !photoPreview && !dataProductInput.path_img_thumb
                      }"
                    >
                      <div
                        v-if="dataProductInput.path_img_thumb && !photoPreview"
                        class="br-10"
                        style="width: 100%; height: 150px;border:1px solid #e4e4e4"
                      >
                        <img
                          :src="dataProductInput.path_img_thumb"
                          class="mb-0 pb-0 br-10 ma-auto  h-100"
                          style="width:100%; object-fit: contain;"
                        />
                      </div>
                      <img
                        :src="photoPreview"
                        style="width: 250px; height: 120px; object-fit: contain"
                        alt=""
                        v-else-if="photoPreview"
                      />
                      <div v-else>
                        <v-icon>mdi-plus</v-icon>
                        <br />
                        {{ $t("catalog.add_photo") }}
                      </div>
                      <div class="productPlaceholder__edit">
                        <v-icon color="success">mdi-pencil</v-icon>
                      </div>
                    </div>
                  </div>
                </label>
                <VBtn
                  class="mt-1"
                  v-if="photoPreview"
                  @click="
                    photoPreview = null;
                    image = null;
                  "
                  depressed
                  >{{ $t("btn.delete") }}
                </VBtn>
                <VFileInput
                  class="d-none"
                  id="product_photo_prev"
                  accept="image/*"
                  v-model="image"
                  @change="makePreview"
                />
              </v-col>
              <v-col cols="7">
                <v-col cols="12">
                  <VTextField
                    v-model="dataProductInput.name"
                    outlined
                    dense
                    color="success"
                    class="br-10"
                    :label="$t('form.title')"
                    :hide-details="!nameProductFormErrors.length"
                    :error-messages="nameProductFormErrors"
                    @keypress="validationForTitle($event)"
                    @paste="validationForTitle($event)"
                    :maxLength="30"
                  />
                </v-col>
                <v-col cols="12">
                  <VTextField
                    v-model="dataProductInput.price"
                    outlined
                    dense
                    color="success"
                    class="br-10"
                    min="1"
                    :label="$t('form.price')"
                    :hide-details="!priceProductFormErrors.length"
                    :error-messages="priceProductFormErrors"
                    @keypress="validationForPrice($event)"
                    @paste="validationForPrice($event)"
                    :maxLength="8"
                  />
                </v-col>
                <v-col cols="12">
                  <VAutocomplete
                    v-model="dataProductInput.uuid_category"
                    outlined
                    dense
                    :items="Object.values(categories)"
                    clearable
                    :item-text="'name'"
                    :item-value="'uuid'"
                    color="success"
                    item-color="success"
                    class="br-10"
                    :label="$t('crm.category')"
                    hide-details
                    @keypress="validationForTitle($event)"
                    @paste="validationForTitle($event)"
                    :maxLength="30"
                  />
                </v-col>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <VBtn @click="galleryDialog = true" class="success-bg text-white" block
              ><v-icon>mdi-image-outline</v-icon>{{ $t("crm.gallery_title") }}</VBtn
            >
          </v-col>
          <v-col cols="12" class="mx-1">
            <v-expansion-panels v-model="expansionPanel">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  {{ $t("form.description") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <VTextarea
                    height="104"
                    no-resize
                    rows="3"
                    color="green"
                    class="br-10"
                    :placeholder="$t('form.description')"
                    v-model="dataProductInput.description"
                    :hide-details="!descriptionProductFormErrors.length"
                    :error-messages="descriptionProductFormErrors"
                    outlined
                    dense
                    maxlength="256"
                  ></VTextarea>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  {{ $t("crm.characteristic") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-col cols="12" style="max-height: 350px;" class="overflow-y-auto mb-7">
                    <vue-editor
                      v-model="dataProductInput.info"
                      @text-change="editorMaxLength"
                      ref="editor"
                    />
                  </v-col>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <v-col cols="12" class="pt-10 pb-0 ml-3">
            <v-slider
              v-model="dataProductInput.discount"
              :label="$t('form.discount')"
              thumb-color="green"
              track-color="green"
              color="green darken-2"
              thumb-label="always"
              hide-details
              step="5"
              ticks
            >
              <template v-slot:thumb-label="{ value }"> {{ value }}% </template>
            </v-slider>
          </v-col>
          <v-col cols="12" class="pt-0 pb-0 ml-3">
            <v-row no-gutters align="center">
              <label class="v-label">{{ $t("form.rating") }}</label>
              <v-rating
                v-model="rating"
                color="green"
                background-color="green"
                empty-icon="mdi-star-outline"
                full-icon="mdi-star"
                half-icon="mdi-star-half-full"
                hover
                length="5"
                half-increments
                size="25"
              ></v-rating>
            </v-row>
          </v-col>
          <v-col cols="12" class="pt-0 ml-3">
            <v-row no-gutters align="center">
              <v-checkbox
                color="green"
                false-value="0"
                true-value="1"
                hide-detail
                :label="$t('form.top_sell')"
                v-model="dataProductInput.is_top_sell"
              >
              </v-checkbox>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <div class="productPlaceholder">
          <div class="productPlaceholder__image">
            <img :src="mainItem.path_img_thumb" alt="" v-if="mainItem.path_img_thumb" />
            <img
              :src="require('@/assets/images/product-desc.png')"
              class="cursor-pointer"
              alt=""
              v-else
              @click="editProduct"
            />
            <div class="productPlaceholder__add" v-if="!mainItem.uuid" @click="editProduct">
              <span></span>
              <span></span>
            </div>
            <div class="productPlaceholder__edit" v-else @click="editProduct">
              <v-icon color="success">mdi-pencil</v-icon>
            </div>
          </div>
          <div class="productPlaceholder__info">
            <div class="productPlaceholder__name">{{ $t("crm.name") }}: {{ mainItem.name }}</div>
          </div>
        </div>
        <v-col cols="12" v-if="type === 'edit' && 'options_details' in mainItem">
          <crmFormItemOption :mainItem="mainItem" :dataProduct="dataProduct" />
        </v-col>
      </v-col>
    </v-row>

    <v-row class="pb-3">
      <!-- <v-col cols="6"> -->
      <!-- <VBtn class="w-100 br-10 text-transform-none" dark @click="$emit('back')">
          {{ $t("btn.cancel") }}
        </VBtn> -->
      <!-- </v-col> -->
      <v-col cols="6" class="mx-auto">
        <VBtn
          class="success-bg text-white text-transform-none br-10 w-100"
          v-if="type !== 'edit'"
          @click="updateProduct"
        >
          {{ $t("btn.create") }}
        </VBtn>
        <VBtn
          class="success-bg text-white text-transform-none br-10 w-100"
          v-if="type === 'edit'"
          :disabled="!isChanged"
          @click="updateProduct"
        >
          {{ $t("btn.update") }}
        </VBtn>
      </v-col>
    </v-row>
    <ConfirmDialogSlots
      :visible="showConfirmChangeItemDialog"
      @close="showConfirmChangeItemDialog = false"
      @onConfirm="
        confirmChangeItem();
        showConfirmChangeItemDialog = false;
      "
      @onCancel="showConfirmChangeItemDialog = false"
      :typeAlert="'error'"
    >
      <template v-slot:header>
        {{ $t("confirmDialog.accept_deleteTitle") }}
      </template>
      <template v-slot:description>
        При зміні товару ви втратите обрані опції
      </template>
    </ConfirmDialogSlots>
    <ConfirmLeftDialog
      :visible="visibleConfirm"
      @close="visibleConfirm = false"
      @delete="visibleConfirm = false"
    />
    <galleryDialog
      :visible="galleryDialog"
      @close="galleryDialog = false"
      v-if="galleryDialog"
      :dataProduct="dataProduct"
    />
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import language from "../../../../../../mixins/language";
import notifications from "../../../../../../mixins/notifications";
import crmForms from "@/services/request/crm/crmForms";
import ConfirmDialogSlots from "../../../../../dialog/ConfirmDialog.vue";
import EventBus from "@/events/EventBus";
import depotService from "@/services/request/depot/depotService";
import { validationForPrice, validationForTitle } from "@/mixins/helpers";
import { VMoney } from "v-money";
import crmFormItemOption from "./crmFormItemOptions.vue";
import { VueEditor } from "vue2-editor";
import _ from "lodash";
import fileSizeValidation from "@/validators/fileSizeProduct";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";
import galleryDialog from "@/components/dialog/galleryDialog.vue";

export default {
  directives: { money: VMoney },
  mixins: [validationMixin, language, notifications],
  name: "crmFormProduct",
  components: {
    ConfirmLeftDialog,
    ConfirmDialogSlots,
    crmFormItemOption,
    VueEditor,
    galleryDialog
  },
  props: {
    uuid_form: {
      required: true,
      type: String
    },
    dataProduct: {
      required: true,
      type: Object,
      default: null
    },
    type: {
      required: true,
      type: String
    }
  },
  data: () => ({
    expansionPanel: undefined,
    showConfirmChangeItemDialog: false,
    changed: false,
    confirmDialog: false,
    visibleConfirm: false,
    rating: 0,
    mainItem: {
      name: "---",
      path_img_thumb: ""
    },
    fixedMainItem: {},
    money: {
      decimal: ".",
      thousands: "",
      precision: 2,
      max: 999.99,
      min: 1.0
    },
    option: null,
    mainProductOptions: [],
    photoPreview: null,
    image: null,
    dataProductInput: [],
    chosenOptions: [],
    categories: [],
    clickedOutside: false,
    galleryDialog: false
  }),
  watch: {
    dataProduct: {
      deep: true,
      handler(e) {
        this.expansionPanel = undefined;
        this.$v.$reset();

        this.getCategories();
        this.setItem();
      }
    },
    mainItem: {
      deep: true,
      handler(e) {
        //
      }
    },
    image: {
      deep: true,
      handler() {
        if (this.image) {
          this.dataProductInput.path_img_thumb = this.image;
        } else {
          this.dataProductInput.path_img_thumb = this.dataProduct.path_img_thumb;
        }
      }
    }
  },
  methods: {
    validationForTitle,
    validationForPrice,
    editorMaxLength() {
      const quill = this.$refs.editor.quill;
      const len = quill.getLength();
      if (len > 256) {
        quill.deleteText(256, len);
      }
    },
    async getCategories() {
      try {
        const data = {
          uuid_form: this.uuid_form
        };
        this.categories = await crmForms.getCategories(data);
      } catch (e) {
        console.log(e);
      }
    },
    onFilterOptions(options, mainItem = false) {
      let mainOptions = {};
      if (mainItem) {
        mainOptions = mainItem?.options_details;
      } else {
        mainOptions = this.mainItem?.options_details;
      }
      options.forEach(option => {
        mainOptions = mainOptions?.filter(e => e.uuid !== option.uuid_option);
      });
      this.mainItem.options_details = mainOptions;
    },

    makePreview() {
      if (this.$v.image.fileSizeValidation) {
        let photoPreview = null;
        const reader = new FileReader();
        reader.readAsDataURL(this.image);
        reader.onloadend = ev => {
          photoPreview = ev.target.result;
          this.photoPreview = photoPreview;
        };
      } else {
        this.image = null;
        this.photoPreview = null;
        this.setErrorNotification(this.$t("form.errors.photoMaxSizeProduct"));
      }
    },
    async updateProduct() {
      if (this.mainItem.uuid) {
        this.$v.dataProductInput.$touch();
        if (!this.$v.dataProductInput.$invalid) {
          try {
            const formData = new FormData();
            if (this.type === "add") {
              formData.append("uuid_form", this.uuid_form);
              formData.append("name", this.dataProductInput.name);
              formData.append("price", this.dataProductInput.price);
              formData.append("is_top_sell", this.dataProductInput.is_top_sell);
              formData.append("rating", this.rating);
              formData.append("discount", this.dataProductInput.discount);
              formData.append("description", this.dataProductInput.description);
              formData.append("uuid_product", this.mainItem.uuid);
              if (this.dataProductInput.uuid_category) {
                formData.append("uuid_category", this.dataProductInput.uuid_category);
              }
              if (this.dataProductInput.info) {
                formData.append("info", this.dataProductInput.info);
              }

              if (this.dataProductInput.path_img_thumb) {
                formData.append("image", this.dataProductInput.path_img_thumb);
              }
              formData.append("uuid_option", this.option || "00000000-0000-0000-0000-000000000000");
            }
            let changedData = [];
            if (this.type === "edit") {
              this.dataProductInput.discount = String(this.dataProductInput.discount);
              this.dataProductInput.rating = String(this.rating);
              if (!this.option) {
                this.dataProductInput.uuid_option = "00000000-0000-0000-0000-000000000000";
              } else {
                this.dataProductInput.uuid_option = this.option;
              }
              if (!this.dataProductInput.uuid_category) {
                this.dataProductInput.uuid_category = "00000000-0000-0000-0000-000000000000";
              }

              changedData = this.$getChangedData(this.dataProductInput, this.dataProduct);
              changedData.forEach(data => {
                if (data.field == "path_img_thumb") {
                  formData.append("image", data.value);
                } else {
                  formData.append(data.field, data.value);
                }
              });

              formData.append("uuid", this.dataProductInput.uuid);
              formData.append("uuid_product", this.mainItem.uuid);

              // //formData.append("uuid_product", this.mainItem.uuid);
            }

            let res;
            if (this.type === "add") res = await crmForms.addProduct(formData);
            if (this.type === "edit") res = await crmForms.updateProduct(formData);

            if (res) {
              this.setSuccessNotification(this.$t("profile.successfully_updated"));
              this.photoPreview = null;
              this.image = null;
              EventBus.$emit("onResetTab");
            }
          } catch (e) {
            console.log(e);
          }
        }
      } else {
        this.setErrorNotification("Додайте товар");
      }
    },
    async getProductInfo(uuid, options) {
      if (!uuid) return;
      try {
        const mainItem = await depotService.getProduct(uuid);
        this.fixedMainItem = mainItem;

        if (options) {
          this.onFilterOptions(options, mainItem);
        } else {
          this.mainItem = mainItem;
        }

        this.mainProductOptions = await depotService.getMyProducts({
          uuid_product: uuid,
          id_state: 810,
          limit: 1000,
          offset: 0
        });
      } catch (e) {
        console.log(e);
      }
      //
    },
    editProduct() {
      if (this.type === "edit" && this.chosenOptions.length) {
        this.showConfirmChangeItemDialog = true;
        return;
      }
      this.$emit("editProduct");
    },
    confirmChangeItem() {
      this.clearOptions();
      this.$emit("editProduct");
    },
    async clearOptions() {
      try {
        await crmForms.clearItemsOptions(this.dataProduct.uuid);
      } catch (e) {
        console.log(e);
      }
    },
    setItem() {
      this.dataProduct.path_img_thumb = this.dataProduct.path_img;
      this.dataProductInput = { ...this.dataProduct };

      if (this.type === "edit") {
        this.getProductInfo(this.dataProductInput.uuid_product);

        this.option = this.dataProductInput.uuid_option;
        this.rating = Number(this.dataProductInput.rating);
      } else {
        this.option = null;
        this.rating = 0;
        this.mainItem = {
          name: "---",
          path_img_thumb: ""
        };
      }
    }
    // handleClickOutside(event) {
    //   if (!this.$el.contains(event.target)) {
    //     this.clickedOutside = true;
    //     if (this.isChanged) {
    //       this.visibleConfirm = true;
    //       this.isChanged = false;
    //     }
    //   }
    // },
    // handlePageClose(event) {
    //   if (this.isChanged && this.clickedOutside) {
    //     event.preventDefault();
    //     event.returnValue = "";
    //   }
    // }
  },
  mounted() {
    // document.addEventListener("click", this.handleClickOutside);
    // window.addEventListener("beforeunload", this.handlePageClose);
    EventBus.$on("crm-form-setProduct", async (product, typeUUID) => {
      await this.getProductInfo(product[typeUUID]);
      this.option = product.uuid_option;
    });
    EventBus.$on("product-options-modified", async options => {
      this.chosenOptions = options;
      //  await this.getProductInfo(this.dataProductInput.uuid_product, options);
    });
    EventBus.$on("product-add-option", async options => {
      await this.getProductInfo(this.dataProductInput.uuid_product, options);
    });
    EventBus.$on("product-filter-option", options => {
      this.onFilterOptions(options);
    });
    EventBus.$on("product-delete-option", async options => {
      await this.getProductInfo(this.dataProductInput.uuid_product, options);
    });

    this.getCategories();
    this.setItem();
  },
  // beforeDestroy() {
  //   document.removeEventListener("click", this.handleClickOutside);
  //   window.removeEventListener("beforeunload", this.handlePageClose);
  // },
  computed: {
    isChanged() {
      const dataProductInput = { ...this.dataProductInput };
      dataProductInput.rating = String(this.rating);
      dataProductInput.discount = String(this.dataProductInput.discount);
      if (
        !_.isEqual(dataProductInput, this.dataProduct) ||
        !_.isEqual(this.mainItem, this.fixedMainItem)
      ) {
        return true;
      } else return false;
    },
    nameProductFormErrors() {
      const errors = [];
      if (!this.$v.dataProductInput.name.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.dataProductInput.name.required && errors.push(this.$t("form.errors.TitleRequierd"));
      return errors;
    },
    priceProductFormErrors() {
      const errors = [];
      if (!this.$v.dataProductInput.price.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.dataProductInput.price.required && errors.push(this.$t("form.errors.PriceRequired"));
      return errors;
    },
    descriptionProductFormErrors() {
      const errors = [];
      // if (!this.$v.dataProductInput.description.$dirty) {
      //   return errors;
      // }
      // // eslint-disable-next-line no-unused-expressions
      // !this.$v.dataProductInput.description.required &&
      //   errors.push(this.$t("form.errors.DescRequired"));
      return errors;
    },
    mainItemOption() {
      if (!this.mainProductOptions) return;
      const data = this.mainProductOptions?.filter(item => item.uuid_option === this.option);
      if (data.length) {
        return data[0].product_details.name || "---";
      }
      return "---";
    }
  },
  validations: {
    dataProductInput: {
      name: { required },
      price: { required }
      // description: { required }
    },
    image: { fileSizeValidation }
  }
};
</script>
<style lang="scss">
.productPlaceholder {
  display: flex;
  // padding-top: 52px;
  &__image {
    width: 150px;
    height: 150px;
    border: 1px dashed grey;
    border-radius: 10px;
    margin-right: 25px;
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__add {
    width: 100%;
    height: 100%;
    background-color: #f1f1f1;
    cursor: pointer;
    &:hover span {
      background-color: green;
    }
    span {
      border-radius: 2px;
      transition: all 0.3s ease;
      width: 50px;
      height: 5px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: grey;
      &:last-child {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }
  }
  &__edit {
    position: absolute;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
  }
  &__info {
    padding-top: 15px;
  }
  &__name {
    font-size: 18px;
    margin-bottom: 15px;
  }
  &__option {
    font-size: 16px;
  }
}
.photo-area:hover {
  cursor: pointer;
  opacity: 0.7;
}

.background-img {
  background-color: #eeeded;
}
</style>
