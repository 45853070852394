<template>
  <div class="crmForms">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <VBtn
          v-bind="attrs"
          v-on="on"
          @click="modalListForms = true"
          :disabled="!boardList.length"
          class="btn-settingBoard success-bg text-white text-transform-none br-10"
        >
          <VIcon>mdi-form-select</VIcon>
        </VBtn>
      </template>
      <span>{{ $t("crm.settingtsTitleForm") }}</span>
    </v-tooltip>

    <VDialog
     :transition="$dialogTransition"
      v-model="modalListForms"
      max-width="700px"
      content-class="cross__dialog dialog-order"
      scrollable
      :persistent="isChanged"
      @click:outside="confirmDialogCheck"
    >
      <VCard class="crmForms__listDialog" :height="$vuetify.breakpoint.smAndDown ? '500' : ''">
        <div class="cross__inner">
          <VBtn fab small elevation="0" @click="confirmDialogCheck()">
            <VIcon>mdi-close</VIcon>
          </VBtn>
        </div>
        <VRow class="ma-0 align-center justify-space-between crmForms__listDialog__header">
          <VCol cols="12" md="6" class="crmForms__listDialog__header-title">
            <VBtn
              @click="confirmDialogCheckBack()"
              small
              plain
              class="br-10 pa-0 ma-0"
              v-if="typeDialog != 0"
            >
              <VIcon>mdi-arrow-left</VIcon>
            </VBtn>
            <span>
              {{$t('form.forms')}}
              </span>
          </VCol>
          <VCol cols="12" md="6" class="crmForms__listDialog__header-nav" v-if="typeDialog === 0">
            <VBtn
              class="ml-2 btn-settingBoard success-bg text-white text-transform-none br-10"
              :class="{ 'w-100': $vuetify.breakpoint.mobile }"
              @click="addNewForm"
            >
              <VIcon :left="$vuetify.breakpoint.mobile">mdi-plus</VIcon>
              {{ $vuetify.breakpoint.mobile ? $t("btn.create") : "" }}
            </VBtn>
          </VCol>
        </VRow>
        <VCardText>
          <VTabsItems v-model="typeDialog">
            <VTabItem>
              <VDivider v-if="!$vuetify.breakpoint.mobile" />
              <v-data-table
                :headers="listFormsHeader"
                :items="listForms.filter(item => item.uuid_board === boardUuid.uuid)"
                item-key="email"
                hide-default-footer
                class="my-table supply crmForms__listDialog-table"
                locale="uk"
                dense
                loader-height="1"
                :items-per-page="listForms.length"
                :no-data-text="$t('table.noData')"
              >
                <!-- eslint-disable-next-line  -->
                <template v-slot:item.btn="{ item }">
                  <VBtn
                    v-if="$vuetify.breakpoint.smAndDown"
                    class="btn-edit sm mr-3"
                    color="primary"
                    dense
                    small
                    @click="editItemForms(item)"
                  >
                    <VIcon dense>
                      mdi-pencil
                    </VIcon>
                  </VBtn>
                  <toggle-button
                    :sync="true"
                    :width="40"
                    :height="17"
                    v-model="item.is_maintenance"
                    :labels="{ checked: 'on', unchecked: 'off' }"
                    @change="onChangeStatus(item)"
                  />
                  <VBtn
                    icon
                    class="sm"
                    color="primary"
                    depressed
                    dense
                    small
                    plain
                    @click="openForm(item.uuid)"
                  >
                    <VIcon small>
                      mdi-link
                    </VIcon>
                  </VBtn>
                  <VBtn
                    icon
                    class="sm"
                    color="error"
                    depressed
                    dense
                    small
                    plain
                    @click="delayItemForms(item.uuid)"
                  >
                    <VIcon small>
                      mdi-close
                    </VIcon>
                  </VBtn>
                </template>
                <!-- eslint-disable-next-line  -->
                <template v-slot:item.name="{ item }">
                  <div>
                    <VBtn
                      v-if="!$vuetify.breakpoint.smAndDown"
                      class="btn-edit sm mr-3"
                      color="primary"
                      dense
                      small
                      @click="editItemForms(item)"
                    >
                      <VIcon dense>
                        mdi-pencil
                      </VIcon>
                    </VBtn>
                    {{ item.name }}
                  </div>
                </template>
                <!-- eslint-disable-next-line  -->
                <!-- <template v-slot:item.is_maintenance="{ item }">
                  <div>

                  </div>
                </template> -->
              </v-data-table>
            </VTabItem>
            <VTabItem>
              <VRow class="my-0 py-0">
                <VCol cols="12">
                  <VTextField
                    v-model="form.name"
                    outlined
                    dense
                    color="success"
                    class="br-10"
                    :placeholder="$t('form.title')"
                    hide-details="auto"
                    :error-messages="nameEditErrors"
                    @keypress="validationForTitle($event)"
                    @paste="validationForTitle($event)"
                  />
                </VCol>
                <VCol v-if="typeDialogForm === 1" cols="12">
                  <VTextarea
                    height="104"
                    no-resize
                    rows="3"
                    color="success"
                    class="br-10"
                    :placeholder="$t('form.addComment')"
                    v-model="form.description"
                    outlined
                    counter
                    :maxlength="255"
                    dense
                  ></VTextarea>
                </VCol>
                <VCol v-if="false" cols="12" class="d-flex">
                  <VAutocomplete
                    dense
                    :items="boardList"
                    outlined
                    :item-value="'uuid'"
                    :item-text="'name'"
                    v-model="form.uuid_board"
                    return-object
                    :hide-details="true"
                    :menu-props="{ bottom: true, offsetY: true }"
                  />
                </VCol>
              </VRow>
            </VTabItem>
          </VTabsItems>
        </VCardText>
        <VCardActions>
          <VContainer>
            <VRow>
              <VCol cols="12" md="12" v-if="typeDialog === 1">
                <VBtn
                  class="success-bg text-white text-transform-none br-10 w-100"
                  v-if="typeDialogForm === 0"
                  @click="updateFormsItem"
                >
                  {{ $t('btn.update') }}
                </VBtn>
                <VBtn
                  class="success-bg text-white text-transform-none br-10 w-100"
                  v-else-if="typeDialogForm === 1"
                  @click="createFormsItem"
                >
                  {{ $t('btn.save') }}
                </VBtn>
              </VCol>
              <!-- <VCol cols="6" class="my-0 py-0"> -->
              <!--                <VBtn-->
              <!--                  class="success-bg text-white text-transform-none br-10 w-100"-->
              <!--                  v-if="typeDialog === 0"-->
              <!--                  @click="updateBoardItem"-->
              <!--                >-->
              <!--                  {{ language.UPDATE }}-->
              <!--                </VBtn>-->
              <!--                <VBtn-->
              <!--                  class="success-bg text-white text-transform-none br-10 w-100"-->
              <!--                  v-else-if="typeDialog === 1"-->
              <!--                  @click="createNewBoard"-->
              <!--                >-->
              <!--                  {{ language.CREATE }}-->
              <!--                </VBtn>-->
              <!-- </VCol> -->
            </VRow>
          </VContainer>
        </VCardActions>
      </VCard>
    </VDialog>
    <CrmFormSetting
      v-if="modalForm"
      :visible="modalForm"
      :data-form="form"
      @close="modalForm = false"
      @back="backModal()"
    ></CrmFormSetting>

    <ConfirmDialogSlots
      :visible="confirmDialog"
      @close="confirmDialog = false"
      @onConfirm="
        confirmDeleteForm();
        confirmDialog = false;
      "
      @onCancel="confirmDialog = false"
      :typeAlert="'error'"
    >
      <template v-slot:header>
        {{ $t("confirmDialog.accept_deleteTitle") }}
      </template>
      <template v-slot:description>
        {{ $t("confirmDialog.accept_deleteDesc") }}
      </template>
    </ConfirmDialogSlots>
    <ConfirmLeftDialog
      :visible="visibleConfirm"
      @close="visibleConfirm = false"
      @delete="onClose"
    />
    <ConfirmLeftDialog
      :visible="visibleConfirmBack"
      @close="visibleConfirmBack = false"
      @delete="cancellationForm"
    />
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import language from "@/mixins/language";
import crmForms from "../../../services/request/crm/crmForms";
import notifications from "../../../mixins/notifications";
import CrmFormSetting from "./crmEditFormDialog/crmEditFormComponent.vue";
import ConfirmDialogSlots from "../../dialog/ConfirmDialog.vue";
import EventBus from "@/events/EventBus";
import {validationForTitle} from "@/mixins/helpers";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";
import _ from "lodash";

export default {
  name: "crmForms",
  props: {
    boardList: {
      required: true
    },
    boardUuid: {
      required: true
    },
    stateUuid: {
      required: true
    }
  },
  mixins: [validationMixin, language, notifications],
  data: () => ({
    confirmDialog: false,
    modalListForms: false,
    modalForm: false,
    typeDialog: 0,
    typeDialogForm: 0,
    deleteUUID: null,
    listFormsHeader: [],
    form: {
      name: "",
      description: "",
      uuid: "",
      uuid_board: ""
    },
    visibleConfirm: false,
    visibleConfirmBack: false,
    isChanged: false,
    listForms: [
      // {
      //   uuid: 'asdasdasdasdasdasdasdasds',
      //   name: 'Test',
      //   description: null,
      //   id_state: '2100',
      //   uuid_board: 'asdasdasdasdasdasdasdasds',
      //   uuid_user: 'asdfasdfasdfasdfasdfa',
      //   time_created: 'asdfasdfasdfasdfasdfa'
      // }
    ]
  }),
  mounted() {
    // {{boardUuid.uuid}}
    // {{stateUuid.uuid}}
   // EventBus.$on("dataForm-modified", data => {

    //  this.form = data;
     // this.fetchData();
   // });
  },
  methods: {
    validationForTitle,
    renderTable() {
      this.listFormsHeader = [
      {
        text: this.$t("form.title"),
        align: "left",
        sortable: false,
        value: "name"
      },
      {
        text: this.$t("form.control"),
        align: "center",
        sortable: false,
        value: "btn",
        width: "10%"
      }
    ]
    },
    onClose() {
      this.$v.$reset();
      this.visibleConfirm = false;
      this.closeDialog();
    },
    // eslint-disable-next-line
    async onChangeStatus({ uuid, is_maintenance }) {
      try {
        const formData = new FormData();
        formData.append("uuid", uuid);
        // eslint-disable-next-line
        formData.append("is_maintenance", is_maintenance ? "0" : "1");
        const res = await crmForms.updateItemForm(formData);
        if (res) {
          this.setSuccessNotification(this.$t('profile.successfully_updated'));
        }
      } catch (e) {
        console.log(e)
      }
    },
    async fetchData() {
      this.listForms = (await crmForms.getListForms()) || [];
      this.fixMaintance();

    },
    fixMaintance() {
      if (!this.listForms.length) return;
      this.listForms.forEach(listForm => {
        if (listForm.is_maintenance === "1") {
          listForm.is_maintenance = false;
        } else {
          listForm.is_maintenance = true;
        }
      });
    },
    confirmDialogCheckBack(){
      if(this.typeDialog == 1){
        if(this.form.name.length != 0 || this.form.description.length != 0){
          this.visibleConfirmBack = true;
        }
        else{
          this.cancellationForm();
        }
      }
      else{
        this.cancellationForm();
      }
    },
    confirmDialogCheck() {
      if(this.typeDialog == 1){
        if(this.form.name.length != 0 || this.form.description.length != 0){
          this.visibleConfirm = true;
        }
        else{
          this.onClose();
        }
      }
      else{
        this.onClose();
      }
    },
    closeDialog() {
      this.modalListForms = false;
      this.typeDialog = 0;
      this.$v.$reset();
    },
    backModal() {
      this.modalForm = false;
      this.modalListForms = true;
    },
    openForm(uuid) {
      const route = this.$router.resolve({
        name: "market",
        params: {
          id: uuid
        }
      });
      window.open(route.href, "_blank");
    },
    addNewForm() {
      this.clearForm();
      this.form.uuid_board = this.boardList[0].uuid;
      this.typeDialog = 1;
      this.typeDialogForm = 1;
    },
    editItemForms(data) {
      // this.typeDialog = 1
      // this.typeDialogForm = 0
      this.modalListForms = false;
      this.form = data;
      this.modalForm = true;
    },
    async confirmDeleteForm() {
      const uuid = this.deleteUUID;
      const respons = await crmForms.delayItemForm(uuid);

      if (respons.status === "success") {
        this.listForms = this.listForms.filter(item => item.uuid !== uuid);
      }
    },
    delayItemForms(uuid) {
      this.deleteUUID = uuid;
      this.confirmDialog = true;
    },
    clearForm() {
      this.form = {
        name: "",
        description: "",
        uuid: "",
        uuid_board: "",
        uuid_product: ""
      };
    },
    cancellationForm() {
      this.typeDialog = 0;
      this.visibleConfirmBack = false;
      this.clearForm();
      this.$v.$reset();
    },
    async updateFormsItem() {
      try {
        const formData = new FormData();
        formData.append("uuid", this.form.uuid);
        formData.append("name", this.form.name);
        formData.append("description", this.form.description);
        const res = await crmForms.updateItemForm(formData);
        if (res) {
          this.setSuccessNotification(this.$t('profile.successfully_updated'));
          this.cancellationForm();
        }
      } catch (e) {
        console.log(e)
      }
    },
    async createFormsItem() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      try {
        const formData = new FormData();
        formData.append("uuid_board", this.boardUuid.uuid);
        formData.append("name", this.form.name);
        const res = await crmForms.addItemForm(formData);
        if (res) {

          this.listForms.push(res);
          this.setSuccessNotification("Форму створено!");
          this.cancellationForm();
        }
      } catch (e) {
        console.log(e)
      }
    }
  },
  computed: {
    nameEditErrors() {
      const errors = [];
      if (!this.$v.form.name.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.form.name.required && errors.push(this.$t("form.errors.TitleRequierd"));
      return errors;
    }
  },
  watch: {
    modalListForms: {
      handler(value) {
        if(this.modalListForms == true) {
          this.renderTable();
          this.fetchData();
        }
        if (!value) {
          this.closeDialog()
        }
      }
    },
    form: {
      deep: true,
      handler(e){
        this.isChanged = !_.isEqual(this.form, {});
      }
    }
  },

  validations: {
    form: {
      name: { required }
    }
  },
  components: {
    CrmFormSetting,
    ConfirmDialogSlots,
    ConfirmLeftDialog
  }
};
</script>

<style lang="scss">
.crmForms {
  &__listDialog {
    &__header {
      &-title {
        padding: 32px 24px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        letter-spacing: 0.03em;
        text-transform: uppercase;
      }
      &-nav {
        display: flex;
        padding: 12px 24px;
        justify-content: flex-end;
      }
    }
    &-table {
      table {
        tbody {
          tr {
            td {
              &:last-child {
                // width: 100px;
              }
            }
          }
        }
      }
    }
  }
}

</style>
