<template>
  <v-dialog
    content-class="cross__dialog"
    v-model="visibity"
    max-width="900"
    :persistent="isChanged"
  >
    <v-card>
      <div class="cross__inner">
        <v-btn fab small elevation="0" @click="$emit('close')" v-if="!isChanged">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-title>
        <v-row justify="space-between">
          <v-col v-if="tagTabs === 1" cols="auto">
            <v-btn plain color="primary" dense @click="onBackTagsList" :disabled="loadingEdit">
              <v-icon left>mdi-arrow-left</v-icon>{{ $t("btn.back") }}
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <span
              v-text="
                tagTabs === 0
                  ? $t('crm.tag_templates')
                  : typeControl === 'edit'
                  ? $t('crm.editing')
                  : $t('crm.creating')
              "
            ></span>
          </v-col>
          <v-col v-if="tagTabs === 0" cols="auto">
            <v-btn
              small
              class="success-bg text-white text-transform-none br-10 w-100"
              dense
              @click="onAddTagTemplate"
              :disabled="loadingEdit"
            >
              <v-icon left>mdi-plus</v-icon>
              {{ $t('crm.add_template') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-tabs-items v-model="tagTabs">
          <v-tab-item>
            <v-data-table
              :headers="tagsListHeader"
              :items="tags"
              item-key="uuid"
              hide-default-footer
              class="my-table supply crmForms__listDialog-table"
              locale="uk"
              dense
              loader-height="1"
              :items-per-page="tags.length"
              :no-data-text="$t('table.noData')"
            >
              <!-- eslint-disable-next-line  -->
              <template v-slot:item.btn="{ item }">
                <v-btn
                  icon
                  class="sm"
                  color="error"
                  depressed
                  dense
                  small
                  plain
                  @click="
                    confirmDialog = true;
                    chosenUUID = item.uuid;
                  "
                  :disabled="loading.load && loading.uuid === item.uuid"
                  :loading="loading.load && loading.uuid === item.uuid"
                >
                  <v-icon small>
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              <!-- eslint-disable-next-line  -->
              <template v-slot:item.tag="{ item }">
                <v-btn
                  class="btn-edit sm mr-3"
                  color="primary"
                  dense
                  small
                  @click="editItem(item)"
                  :disabled="loading.load && loading.uuid === item.uuid"
                >
                  <v-icon dense>
                    mdi-pencil
                  </v-icon>
                </v-btn>
                {{ item.tag }}
              </template>
            </v-data-table>
            <v-divider />
            <!-- <VListItem @click="onAddTagTemplate" dense>
              <v-list-item-icon>
                <v-icon color="#808080">mdi-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title style="color:#808080"> Додати шаблон</v-list-item-title>
              </v-list-item-content>
            </VListItem> -->
          </v-tab-item>
          <v-tab-item>
            <v-divider class="mb-1 mb-5"></v-divider>
            <v-row>
              <v-col cols="6">
                <VTextField
                  v-model="tagInfo.tag"
                  dense
                  outlined
                  :placeholder="$t('crm.name')"
                  hide-details="auto"
                  :error-messages="tagEditErrors"
                  maxlength="25"
                  />
                </v-col>
                <v-col cols="6">
                  <VTextField
                  v-model="tagInfo.utm_source"
                  dense
                  outlined
                  placeholder="utm_source"
                  hide-details="auto"
                  :error-messages="utmSourceErrors"
                  maxlength="25"
                />
              </v-col>
              <v-col cols="6">
                <VTextField
                  v-model="tagInfo.utm_medium"
                  dense
                  outlined
                  placeholder="utm_medium"
                  hide-details="auto"
                  maxlength="25"
                />
              </v-col>
              <v-col cols="6">
                <VTextField
                  v-model="tagInfo.utm_term"
                  dense
                  outlined
                  placeholder="utm_term"
                  hide-details="auto"
                  maxlength="25"
                />
              </v-col>
              <v-col cols="6">
                <VTextField
                  v-model="tagInfo.utm_content"
                  dense
                  outlined
                  placeholder="utm_content"
                  hide-details="auto"
                  maxlength="25"
                />
              </v-col>
              <v-col cols="6">
                <VTextField
                  v-model="tagInfo.utm_campaign"
                  dense
                  outlined
                  placeholder="utm_campaign"
                  hide-details="auto"
                  maxlength="25"
                />
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions>
        <v-row v-if="tagTabs === 1 && typeControl === 'edit'">
          <v-col cols="6">
            <v-btn
              class="w-100 br-10 text-transform-none"
              :dark="!loadingEdit"
              @click="onBackTagsList"
              :disabled="loadingEdit"
            >
              {{ $t("btn.cancel") }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              class="success-bg text-white text-transform-none br-10 w-100"
              @click="onUpdateTagInfo"
              :disabled="!isChanged || loadingEdit"
            >
              {{ $t("btn.update") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-else-if="tagTabs === 1 && typeControl === 'add'">
          <v-col cols="6">
            <v-btn
              class="w-100 br-10 text-transform-none"
              :dark="!loadingEdit"
              @click="onBackTagsList"
              :disabled="loadingEdit"
            >
              {{ $t("btn.cancel") }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              class="success-bg text-white text-transform-none br-10 w-100"
              @click="onCreateTagInfo"
              :disabled="!isChanged || loadingEdit"
            >
              {{ $t("btn.create") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <ConfirmDialogSlots
      :visible="confirmDialog"
      @close="confirmDialog = false"
      @onConfirm="
        deleteItem();
        confirmDialog = false;
      "
      @onCancel="confirmDialog = false"
      :typeAlert="'error'"
    >
      <template v-slot:header>
        {{ $t("confirmDialog.accept_deleteTitle") }}
      </template>
      <template v-slot:description>
        {{ $t("confirmDialog.accept_deleteDesc") }}
      </template>
    </ConfirmDialogSlots>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import notifications from "../../../mixins/notifications";
import EventBus from "@/events/EventBus";
import crmService from "../../../services/request/crm/crmService";
import ConfirmDialogSlots from "../../dialog/ConfirmDialog.vue";

export default {
  mixins: [validationMixin, notifications],
  components: {
    ConfirmDialogSlots
  },
  props: {
    visible: {
      require: true
    },
    uuid_board: {
      require: true
    },
    tags: {
      require: true
    }
  },
  validations: {
    tagInfo: {
      tag: { required },
      utm_source: { required },
    }
  },
  data: () => ({
    chosenUUID: null,
    confirmDialog: false,
    tagsListHeader: [],
    tagTabs: 0,
    tagInfo: {},
    tagInfoCopy: {},
    loading: {
      load: false,
      uuid: null
    },
    isChanged: false,
    loadingEdit: false,
    typeControl: null
  }),
  watch: {
    visible(newValue) {
      if (newValue) {
        this.renderTable();
      }
    },
    tagInfo: {
      deep: true,
      handler() {
        this.isChanged = !_.isEqual(this.tagInfo, this.tagInfoCopy);
      }
    }
  },
  mounted() {
      this.renderTable();
  },
  methods: {
    loadHendler(type, uuid) {
      this.loading.load = type;
      this.loading.uuid = uuid;
    },
    async deleteItem() {
      const uuid = this.chosenUUID;
      try {
        if (!uuid) return;
        this.loadHendler(true, uuid);
        await crmService.deleteTagsTemplateList(uuid);
        this.renderTable();
        EventBus.$emit("tags-modified");
        this.loadHendler(false, uuid);
      } catch (e) {
        this.loadHendler(false, uuid);
        console.log(e)
      }
    },
    onAddTagTemplate() {
      this.typeControl = "add";
      this.tagTabs = 1;
      this.tagInfo = {};
    },
    editItem({ uuid }) {
      this.typeControl = "edit";
      this.tagTabs = 1;
      this.tagInfo = { ...this.tags.find(tag => tag.uuid === uuid) };
      this.tagInfoCopy = { ...this.tagInfo };
    },
    async onCreateTagInfo() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loadingEdit = true;
        const formData = new FormData();
        formData.append("uuid_board", this.uuid_board);
        if (this.tagInfo.tag) formData.append("tag", this.tagInfo.tag);
        if (this.tagInfo.utm_source) formData.append("utm_source", this.tagInfo.utm_source);
        if (this.tagInfo.utm_medium) formData.append("utm_medium", this.tagInfo.utm_medium);
        if (this.tagInfo.utm_term) formData.append("utm_term", this.tagInfo.utm_term);
        if (this.tagInfo.utm_content) formData.append("utm_content", this.tagInfo.utm_content);
        if (this.tagInfo.utm_campaign) formData.append("utm_campaign", this.tagInfo.utm_campaign);

        await crmService.createTagsTemplateList(formData);
        EventBus.$emit("tags-modified");
        this.tagTabs = 0;
        this.loadingEdit = false;
        this.tagInfo = {};
        this.tagInfoCopy = {};
        this.$v.$reset();
      } catch (e) {
        this.loadingEdit = false;
        this.$v.$reset();
        console.log(e)
      }
    },
    async onUpdateTagInfo() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loadingEdit = true;
        const formData = new FormData();
        formData.append("uuid", this.tagInfo.uuid);
        const changedData = this.$getChangedData(this.tagInfo, this.tagInfoCopy);
        changedData.forEach(data => {
          formData.append(data.field, data.value);
        });
        await crmService.updateTagsTemplateList(formData);
        EventBus.$emit("tags-modified");
        this.tagTabs = 0;
        this.loadingEdit = false;
        this.tagInfo = {};
        this.tagInfoCopy = {};
        this.$v.$reset();
      } catch (e) {
        this.loadingEdit = false;
        this.$v.$reset();
        console.log(e)
      }
    },
    onBackTagsList() {
      this.tagTabs = 0;
      this.tagInfo = {};
      this.tagInfoCopy = {};
    },
    renderTable() {
      this.tagsListHeader = [
        {
          text: this.$t("crm.name"),
          align: "left",
          sortable: false,
          value: "tag",

        },
        {
          text: "utm_source",
          align: "left",
          sortable: false,
          value: "utm_source"
        },
        {
          text: "utm_medium",
          align: "left",
          sortable: false,
          value: "utm_medium"
        },
        {
          text: "utm_term",
          align: "left",
          sortable: false,
          value: "utm_term"
        },
        {
          text: "utm_content",
          align: "left",
          sortable: false,
          value: "utm_content"
        },
        {
          text: "utm_campaign",
          align: "left",
          sortable: false,
          value: "utm_campaign"
        },
        {
          text: this.$t("crm.control"),
          align: "center",
          sortable: false,
          value: "btn",
          width: "10%",
          fixed: true,
        }
      ];
    }
  },
  computed: {
    tagEditErrors() {
      const errors = [];
      if (!this.$v.tagInfo.tag.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.tagInfo.tag.required && errors.push(this.$t("form.errors.TitleRequierd"));
      return errors;
    },
    utmSourceErrors() {
      const errors = [];
      if (!this.$v.tagInfo.utm_source.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.tagInfo.utm_source.required && errors.push(this.$t("form.errors.UtmSourceRequired"));
      return errors;
    },
    visibity: {
      get() {
        return this.visible;
      },
      set() {
        this.onBackTagsList();
        return this.$emit("close");
      }
    }
  }
};
</script>
