<template>
  <div class="filter__wrapper">
    <div class="filter__inner">
      <VCol cols="12">
        <VMenu :close-on-content-click="false" :offset-y="true" v-model="isFilterMenu">
          <template v-slot:activator="{ on, attrs }">
            <VBtn color="green" outlined v-bind="attrs" v-on="on">
              <VIcon left>mdi-filter-variant</VIcon>
              {{ $t("supply.filters") }}
            </VBtn>
          </template>
          <VCard>
            <VCardTitle>
              {{ $t("supply.searched_filters") }}
              <VDivider class="ml-5" />
            </VCardTitle>
            <VCardText>
              <VRow>
                <VCol cols="6" xl="4" md="4" sm="6">
                  <VTextField
                    dense
                    hide-details
                    outlined
                    label="#"
                    class="text-caption"
                    v-model="filterProps.number"
                    color="green"
                    inputmode="numeric"
                  />
                </VCol>
                <VCol cols="6" xl="4" md="4" sm="6">
                  <VTextField
                    dense
                    hide-details
                    outlined
                    label="ТТН"
                    class="text-caption"
                    color="green"
                    v-model="filterProps.ttn"
                    inputmode="numeric"
                  />
                </VCol>
              </VRow>
              <VRow>
                <VCol cols="6" xl="4" md="4" sm="6">
                  <VTextField
                    dense
                    hide-details
                    outlined
                    :label="$t('form.name')"
                    class="text-caption"
                    color="green"
                    v-model="filterProps.delivery_address_details__name"
                  />
                </VCol>
                <VCol cols="6" xl="4" md="4" sm="6">
                  <VTextField
                    dense
                    hide-details
                    outlined
                    :label="$t('form.surname')"
                    class="text-caption"
                    color="green"
                    v-model="filterProps.delivery_address_details__surname"
                  />
                </VCol>
              </VRow>
              <VRow>
                <VCol cols="6" xl="4" md="4" sm="6">
                  <VTextField
                    dense
                    hide-details
                    outlined
                    :label="$t('table.phone')"
                    class="text-caption"
                    color="green"
                    v-model="filterProps.delivery_address_details__phone"
                    inputmode="numeric"
                  />
                </VCol>
                <VCol cols="6" xl="4" md="4" sm="6">
                  <VMenu
                    ref="menuFirstDate"
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    class="text-caption"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <VTextField
                        outlined
                        :placeholder="$t('form.time_created')"
                        v-model="dateRangeText"
                        readonly
                        append-icon="mdi-calendar"
                        dense
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                        style="width: 220px"
                      >
                        <template v-slot:append-outer>
                          <v-slide-x-reverse-transition>
                            <VIcon
                              style="position: relative; bottom: 4px;"
                              @click="onCancelInterval"
                              v-if="time_created_period.length >= 1"
                            >mdi-close</VIcon
                            >
                          </v-slide-x-reverse-transition>
                        </template>
                      </VTextField>
                    </template>
                    <v-date-picker
                      v-model="time_created_period"
                      range
                      no-title
                      scrollable
                      :locale="currentLanguage.key"
                      min="2021"
                      :max="new Date().toISOString().substr(0, 10)"
                      @input="onChangeDatePeriod"
                    >
                    </v-date-picker>
                  </VMenu>
                </VCol>
              </VRow>
            </VCardText>
            <VDivider class="mx-1" />
            <VCardActions>
              <!-- <VBtn black text block>
                Скасувати
              </VBtn> -->
              <VBtn class="success-bg white--text" block @click="onApply">
                {{ $t("supply.applyBtn") }}
              </VBtn>
            </VCardActions>
          </VCard>
        </VMenu>
        <VBtn
          class="ml-1"
          color="error darken-2"
          outlined
          v-if="Object.keys(chipItems).length > 1"
          @click="onClearFilter"
        >
          <VIcon>
            mdi-filter-variant-remove
          </VIcon>
        </VBtn>
      </VCol>
      <VCol class="py-0">
        <VRow no-gutters justify="center">
          <div v-for="(item, key) in chipItems" :key="key">
            <VChip
              v-if="item"
              class="ma-1"
              small
              outlined
              color="green darken-1"
              close
              @click:close="onRemoveFilter(key)"
            >
              <b>{{ FILTER_ITEMS[key] }}</b> : {{ item }}
            </VChip>
          </div>
        </VRow>
      </VCol>
    </div>
  </div>
</template>

<script>
import user from "@/mixins/user";
import { mapGetters } from "vuex";
import StatusCircle from "@/components/move-list/StatusCircle.vue";

export default {
  mixins: [user],
  data: () => ({
    dateRangeText: '',
    time_created_period: [],
    filterProps: {
      time_created: "",
      date_created_begin: "",
      date_created_end: "",
    },
    chipItems: {},
    dateMenu: false,
    isFilterMenu: false
  }),
  components: {
    StatusCircle
  },
  methods: {
    onCancelInterval() {
      this.time_created_period = [];
      this.filterProps.date_created_begin = "";
      this.filterProps.date_created_end = "";
      this.filterProps.time_created = "";
      this.dateRangeText = "";
    },
    onChangeDatePeriod() {
      if (this.time_created_period.length === 2) {
        if(this.time_created_period[0] === this.time_created_period[1]) {
          this.filterProps.time_created = this.time_created_period[0]
          this.dateRangeText = this.time_created_period[0];
          this.time_created_period.splice(1, 1);
        } else {
          this.filterProps.time_created = "";
          const firstDate = new Date(this.time_created_period[0]);
          const secondDate = new Date(this.time_created_period[1]);
          if (firstDate > secondDate) {
            this.filterProps.date_created_begin = this.time_created_period[1];
            this.filterProps.date_created_end = this.time_created_period[0];
            this.dateRangeText = `${this.time_created_period[1]} ~ ${this.time_created_period[0]}`;
          } else {
            this.filterProps.date_created_begin = this.time_created_period[0];
            this.filterProps.date_created_end = this.time_created_period[1];
            this.dateRangeText = this.time_created_period.join(' ~ ');
          }
        }
      } else if (this.time_created_period.length === 1) {
        this.filterProps.date_created_begin = "";
        this.filterProps.date_created_end = "";
        this.dateRangeText = this.time_created_period[0];
        this.filterProps.time_created = this.time_created_period[0];
      }
    },
    onClearFilter() {
      this.chipItems = {};
      this.filterProps = {};
      this.setChipsValue();
      this.onApply();
    },
    onRemoveFilter(e) {
      this.filterProps[e] = "";
      this.setChipsValue();
      this.onApply();
    },
    setChipsValue() {
      this.chipItems = {};
      Object.keys(this.filterProps).forEach(item => {
        if (this.filterProps[item] !== "") {
          this.chipItems[item] = this.filterProps[item];
        }
      });
    },
    onApply() {
      this.setChipsValue();
      this.isFilterMenu = false;
      this.$emit("onApplyFilter", this.filterProps);
    }
  },
  mounted() {
    this.filterProps = {
      ...this.filterPropsExact
    };
    this.setChipsValue();
  },
  props: {
    filterPropsExact: {
      require: true
    }
  },
  computed: {
    ...mapGetters(["currentLanguage"]),
    NextYear() {
      const event = new Date().toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }).substr(0, 10);

      const [month, day, year] = event.split(/[\s,\/]+/);

      const formattedEvent = `${year}-${month}-${day}`;

      console.log(formattedEvent);
      return formattedEvent;
    },
    FILTER_ITEMS() {
      return {
        number: "#",
        ttn: "TTН",
        delivery_address_details__name: this.$t("form.name"),
        delivery_address_details__surname: this.$t("form.surname"),
        delivery_address_details__phone: this.$t("form.phone_number"),
        time_created: this.$t("form.time_created"),
        state: this.$t("form.status")
      };
    }
  }
};
</script>

<style lang="scss">
.filter {
  &__wrapper {
    margin: 0 8px;
    height: auto;
    border: 1px solid #dedede;
  }
  &__inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}
</style>
