<template>
  <div>
    <div v-if="chosenOptions.length">
      {{ $t('crm.chosen_options') }}:
      <v-divider />
      <v-simple-table class="my-table supply" dense>
        <thead>
          <tr>
            <th>
              {{ $t('crm.name') }}
            </th>
            <th>{{ $t('crm.option') }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="option in chosenOptions" :key="option.uuid">
            <v-hover v-slot="{ hover }">
              <td class="lg">
                <div
                  v-if="editInputData.show && editInputData.uuid === option.uuid"
                  class="py-2 d-flex"
                >
                  <v-icon small class="mr-1" color="yellow darken-2" @click="onCloseEdit(option)"
                    >mdi-close</v-icon
                  >
                  <v-text-field
                    dense
                    solo
                    hide-details="auto"
                    v-model="inputOptionName"
                    :disabled="loading"
                    :loading="loading"
                    @keypress="onKeyChangeOptionName($event, option)"
                    ref="inputName"
                  />
                </div>

                <div v-else>
                  <v-row align="center" justify="space-between">
                    <v-col cols="8">
                      {{ option.name }}
                    </v-col>
                    <v-col cols="auto">
                      <VBtn
                        icon
                        small
                        v-if="option.is_default === '1'"
                        color="primary"
                        :loading="loadingOption.uuid === option.uuid && loadingOption.isLoading"
                      >
                        <v-icon dense>mdi-star</v-icon>
                      </VBtn>
                      <VBtn
                        icon
                        small
                        v-if="hover && option.is_default === '0'"
                        :loading="loadingOption.uuid === option.uuid && loadingOption.isLoading"
                        :disabled="loadingOption.uuid === option.uuid && loadingOption.isLoading"
                        @click="onChoseDefaultOption(option)"
                      >
                        <v-icon dense>mdi-star-outline</v-icon>
                      </VBtn>
                    </v-col>
                  </v-row>
                </div>
              </td>
            </v-hover>
            <td>
              {{ option.original_name }}
            </td>
            <td class="sm text-center">
              <VBtn
                icon
                class="sm"
                color="yellow darken-2"
                depressed
                dense
                small
                plain
                v-if="!editInputData.show || editInputData.uuid !== option.uuid"
                @click="onEditOption(option)"
              >
                <v-icon small>
                  mdi-pencil
                </v-icon>
              </VBtn>
              <VBtn
                v-if="editInputData.show && editInputData.uuid === option.uuid"
                icon
                class="sm"
                color="success darken-2"
                depressed
                dense
                small
                plain
                :disabled="option.name === inputOptionName || loading"
                :loading="loading"
                @click="onChangeOptionName(option)"
              >
                <v-icon small>
                  mdi-check
                </v-icon>
              </VBtn>
              <VBtn
                icon
                class="sm"
                color="error"
                depressed
                dense
                small
                plain
                @click="deleteOption(option)"
                :disabled="loadingData.uuid === option.uuid && loadingData.loading"
                :loading="loadingData.uuid === option.uuid && loadingData.loading"
              >
                <v-icon small>
                  mdi-close
                </v-icon>
              </VBtn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-divider class="my-5" />
    </div>
    <div v-if="hasOptions">
      {{ $t('crm.available_options') }}:
      <v-divider />
      <v-simple-table class="my-table supply" dense>
        <thead>
          <tr>
            <th>{{ $t('crm.option') }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="option in mainItem.options_details" :key="option.uuid">
            <td>{{ option.name }}</td>
            <td class="sm text-center">
              <VBtn
                icon
                class="sm"
                color="success"
                depressed
                dense
                small
                plain
                @click="addOptions(option)"
                :disabled="loadingData.uuid === option.uuid && loadingData.loading"
                :loading="loadingData.uuid === option.uuid && loadingData.loading"
              >
                <v-icon small>
                  mdi-plus
                </v-icon>
              </VBtn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
    <!-- <div>
      <VCol cols="12" class="d-flex">
        <VTextField
          v-model="inputOptionName"
          outlined
          dense
          color="green"
          class="br-10"
          label="Назва опції"
          hide-details="auto"
        />
        <VBtn
          class="ml-2 btn-settingBoard success-bg text-white text-transform-none br-10"
          @click="onAddInputOption"
        >
          <v-icon>mdi-plus</v-icon>
        </VBtn>
      </VCol>
    </div> -->
  </div>
</template>

<script>
import notifications from "@/mixins/notifications";
import crmForms from "@/services/request/crm/crmForms";
// import ConfirmDialogSlots from "../../dialog/ConfirmDialog.vue";
import EventBus from "@/events/EventBus";
import depotService from "@/services/request/depot/depotService";
import ClickOutside from "vue-click-outside";

export default {
  directives: {
    ClickOutside
  },
  data: () => ({
    defaultOption: "",
    chosenOptions: [],
    loadingData: {
      loading: false,
      uuid: null
    },
    inputOptionName: "",
    editInputData: {
      show: false,
      uuid: null
    },
    loading: false,
    loadingOption: {
      isLoading: false,
      uuid: null
    }
  }),
  watch: {
    hasOptions: {
      handler(newValue) {
        if (newValue >= 0) {
          this.fetchAndEmitOptions();
        }
      }
    },
    "dataProduct.uuid": {
      deep: true,
      handler(e) {
        this.getFormItemOptions();
      }
    },
    "mainItem.uuid": {
      deep: true,
      handler(e) {
        this.getFormItemOptions();
      }
    }
  },
  mounted() {
    this.fetchAndEmitOptions();
    //
    // if(!this.defaultOption && this.chosenOptions.length){
    //   this.onChoseDefaultOption(this.chosenOptions[0])
    // }
  },
  computed:{
    hasOptions(){
      return this.mainItem?.options_details.length;
    }
  },
  methods: {
    async fetchAndEmitOptions() {
      await this.getFormItemOptions();
      EventBus.$emit("product-filter-option", this.chosenOptions);
    },
    async onChoseDefaultOption({ uuid }) {
      try {
        this.loadingOption = {
          uuid: uuid,
          isLoading: true
        };
      await crmForms.setOptionDefault(uuid)
      this.getFormItemOptions();
        this.loadingOption = {
          uuid: uuid,
          isLoading: false
        };
      } catch (e) {
        this.loadingOption = {
          uuid: uuid,
          isLoading: false
        };
        console.log(e)
      }
    },
    onCloseEdit(option) {
      this.inputOptionName = option.name;
      this.editInputData = { show: false, uuid: null };
    },
    onKeyChangeOptionName(e, option) {
      if (e.keyCode === 13) {
        this.onChangeOptionName(option);
      }
    },
    async onChangeOptionName({ uuid }) {
      try {
        this.loading = true;

        const formData = new FormData();
        formData.append("uuid", uuid);
        formData.append("name", this.inputOptionName);
        await crmForms.updateOptionName(formData);
        this.editInputData = {
          show: false,
          uuid: null
        };
        await this.getFormItemOptions();
        EventBus.$emit("product-add-option", this.chosenOptions);
        this.loading = false;
      } catch (e) {
        console.log(e)
        this.loading = false;
      }
    },
    onEditOption(option) {
      this.editInputData = {
        show: true,
        uuid: option?.uuid
      };
      this.inputOptionName = option.name;
      // this.$refs?.inputName[0]?.$el.focus()
    },
    async addOptions({ name, uuid }) {
      try {
        const existingOption = this.chosenOptions.find(option => option.uuid_option === uuid);
        if (existingOption) {
          this.setErrorNotification(this.$t('crm.option_already_exists'))
          return;
        }
        this.loadingData = {
          loading: true,
          uuid: uuid
        };
        const formData = new FormData();
        formData.append("uuid_form_item", this.dataProduct.uuid);
        formData.append("uuid_option", uuid);
        formData.append("name", name);
        await crmForms.AddOptions(formData);
        await this.getFormItemOptions();
        EventBus.$emit("product-add-option", this.chosenOptions);
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingData = {
          loading: false,
          uuid: uuid
        };
      }
    },
    async getFormItemOptions() {
      try {
        const params = {
          uuid_form_item: this.dataProduct.uuid
        };
        this.chosenOptions = await crmForms.getFormItemOptions(params);
        EventBus.$emit("product-options-modified", this.chosenOptions);
      } catch (e) {
        console.log(e)
      }
    },
    async deleteOption({ uuid }) {
      try {
        this.loadingData = {
          loading: true,
          uuid: uuid
        };
        await crmForms.deleteFormItemOption(uuid);
        await this.getFormItemOptions();
        EventBus.$emit("product-delete-option", this.chosenOptions);
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingData = {
          loading: false,
          uuid: uuid
        };
      }
    }
  },
  props: {
    mainItem: {
      require
    },
    dataProduct: {
      require
    }
  },
  mixins: [notifications]
};
</script>

<style></style>
