<template>
  <div>
    <v-row class="mt-1">
      <v-col cols="12">
        <vue2-tinymce-editor v-model="dataForm.header" :options="options"></vue2-tinymce-editor>
      </v-col>
    </v-row>
    <!-- <v-row class="pb-3">
      <v-col cols="6">
        <VBtn class="w-100 br-10 text-transform-none" dark @click="backModal()">
          {{ $t("btn.back") }}
        </VBtn>
      </v-col>
      <v-col cols="6">
        <VBtn
          class="success-bg text-white text-transform-none br-10 w-100"
          @click="updateFormsItem"
          :disabled="!isEqualFormData"
        >
          {{ $t("btn.save") }}
        </VBtn>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
import _ from "lodash";
import { Vue2TinymceEditor } from "vue2-tinymce-editor";

import crmForms from "@/services/request/crm/crmForms";
import notifications from "@/mixins/notifications";
import EventBus from "@/events/EventBus";

export default {
  data: () => ({
    options: {
      height: 500,
      plugins:'code image fullscreen importcss table media preview',
      toolbar1:'fontselect | fontsizeselect | formatselect | bold italic underline strikethrough forecolor backcolor',
      toolbar2:'alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | link table removeformat code',
    },
    isEqualFormData: false
  }),
  mixins: [notifications],
  components: {
    Vue2TinymceEditor
  },
  props: {
    dataForm: {
      require: true
    }
  },
  mounted() {
    document.addEventListener("focusin", function(e) {
      if (e.target.closest(".mce-window") !== null) {
        e.stopImmediatePropagation();
      }
    });

  },
  watch: {
  },
  methods: {
    backModal() {
      this.$emit("back");
    },
    async updateFormsItem() {
      try {
        const formData = new FormData();
        formData.append("uuid", this.dataForm.uuid);
        formData.append("header", this.dataForm.header);
        const res = await crmForms.updateItemForm(formData);
        if (res) {
          this.setSuccessNotification(this.$t('profile.successfully_updated'));
          this.isEqualFormData = false;
          // EventBus.$emit("dataForm-modified", this.dataForm);
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
};
</script>

<style>
body {
  margin: unset;
}
</style>
