<template>
  <div>
    <v-col class="col-12 mb-0 pb-0">
      <v-text-field
        v-model="category.name"
        outlined
        dense
        class="br-10"
        :label="$t('form.title')"
        :error-messages="nameError"
        maxLength="30"
        color="success"
        @keypress="validationForTitle($event)"
        @paste="validationForTitle($event)"
      ></v-text-field>
    </v-col>
    <v-col class="col-12 mt-0 pt-0">
      <v-btn
        v-if="editedItem == editItem"
        @click="onSave"
        class="success-bg text-white text-transform-none br-10 w-100"
        >{{$t('btn.save')}}</v-btn
      >
      <v-btn
        v-if="editedItem != editItem"
        :disabled="!isChanged"
        @click="onUpdate"
        class="success-bg text-white text-transform-none br-10 w-100"
        >{{$t('btn.update')}}</v-btn
      >
    </v-col>
  </div>
</template>

<script>
import crmFormsService from "@/services/request/crm/crmForms";
import notifications from "@/mixins/notifications";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import language from "@/mixins/language";
import _ from "lodash";
import EventBus from "@/events/EventBus";
import {validationForTitle} from "@/mixins/helpers";

export default {
  props: {
    formUuid: {
      require: true,
    },
    editedItem: {
      require: false,
    },
    editItem: {
      require: false,
    },
  },
  mixins: [notifications, validationMixin, language],
  data: () => ({
    isChanged: false,
    category: {
      name: ""
    },
  }),
  validations: {
    category: {
      name: {
        required,
      },
    },
  },
  mounted() {
    if (this.editedItem) {
      this.category = { ...this.editedItem };
    }
  },
  methods: {
    validationForTitle,
    async onSave() {
      this.$v.category.$touch();
      if (!this.$v.category.$invalid) {
        try {
          const formData = new FormData();
          formData.append("uuid_form", this.formUuid);
          formData.append("name", this.category.name);
          await crmFormsService.addCategory(formData).then(() => {
            this.category = {};
            this.$emit("mutated");
          });
        } catch (e) {
          console.log(e)
        }
      }
    },
    async onUpdate() {
      this.$v.category.$touch();
      if (!this.$v.category.$invalid) {
        try {
          const formData = new FormData();
          formData.append("uuid", this.editedItem.uuid);
          formData.append("name", this.category.name);
          await crmFormsService.updateCategory(formData).then(() => {
            this.category = {};
            this.$emit("mutated");
          });
        } catch (e) {
          console.log(e)
        }
      }
    },
  },
  computed: {
    nameError() {
      const errors = [];
      if (!this.$v.category.name.$dirty) {
        return errors;
      }
      !this.$v.category.name.required &&
        errors.push(
          this.language.isRequired(this.$t("form.title"))
        );
      return errors;
    },
  },
  watch: {
    editedItem: {
      deep: true,
      handler(e) {
        this.category = { ...this.editedItem };
      }
    },
    category: {
      deep: true,
      handler(e) {
        this.isChanged = !_.isEqual(this.category, this.editedItem);
        EventBus.$emit("is-changed", this.isChanged);

      },
    },
  },
};
</script>
